import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { geoAzimuthalEqualArea, geoPath, geoGraticule } from 'd3-geo';
import worldData from '../data/world-50m.json';
import { feature } from 'topojson-client';
import types from '../components/AircraftTypes'; // Corrected import

// Define renderAircraftIcon function outside of the component
function renderAircraftIcon(context, type, coordinates, pathGenerator) {
  const path = types[type]; // Corrected variable name
  if (path) {
	const { x, y } = pathGenerator(coordinates); // Project coordinates onto the canvas
	context.fillStyle = "red"; // Set fill color
	context.strokeStyle = "black"; // Set stroke color
	context.lineWidth = 1; // Set line width
	context.beginPath();
	context.moveTo(x, y);
	context.lineTo(x + 10, y); // Adjust icon size as needed
	context.lineTo(x, y + 10); // Adjust icon size as needed
	context.closePath();
	context.fill();
	context.stroke();
  }
}

function CertificateMap({ routeUrl, onImageReady, type }) {
  const svgRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // State to track data loading
  const width = 1600;
  const height = 800;

  useEffect(() => {
	const svg = d3.select(svgRef.current)
	  .attr("width", width)
	  .attr("height", height)
	  .style("background", "#efefef");

	const projection = geoAzimuthalEqualArea()
	  .scale(2150)
	  .rotate([0, -90])
	  .translate([width / 2, height / 2])
	  .clipAngle(90);

	const pathGenerator = geoPath().projection(projection);

	// Draw graticule
	const graticule = geoGraticule();
	svg.append("path")
	  .datum(graticule())
	  .attr("class", "graticule")
	  .attr("d", pathGenerator)
	  .attr("fill", "none")
	  .attr("stroke", "#222")
	  .attr("stroke-width", 0.5);

	// Draw map
	svg.append("path")
	  .datum(feature(worldData, worldData.objects.countries))
	  .attr("d", pathGenerator)
	  .attr("fill", "#000")
	  .attr("stroke", "#999");

	// North Pole marker
	const northPole = [0, 90];
	const northPoleCoordinates = projection(northPole);

	// Draw a cross at the North Pole
	const crossSize = 20; // Size of the cross arms
	svg.append("line") // Horizontal line
	  .attr("x1", northPoleCoordinates[0] - crossSize)
	  .attr("y1", northPoleCoordinates[1])
	  .attr("x2", northPoleCoordinates[0] + crossSize)
	  .attr("y2", northPoleCoordinates[1])
	  .attr("stroke", "black")
	  .attr("stroke-width", 6);

	svg.append("line") // Vertical line
	  .attr("x1", northPoleCoordinates[0])
	  .attr("y1", northPoleCoordinates[1] - crossSize)
	  .attr("x2", northPoleCoordinates[0])
	  .attr("y2", northPoleCoordinates[1] + crossSize)
	  .attr("stroke", "black")
	  .attr("stroke-width", 6);

	// Add label next to the North Pole
	svg.append("text")
	  .attr("x", northPoleCoordinates[0] + 20) // Position text right of the cross
	  .attr("y", northPoleCoordinates[1] - 10) // Align text vertically with the center of the cross
	  .text("NORTH POLE")
	  .attr("font-family", "Times New Roman")
	  .attr("font-size", "24px")
	  .attr("fill", "black");

	// Label text
	svg.append("text")
	  .attr("x", width - 620)  // Slightly offset from the left edge of the rectangle
	  .attr("y", height - 10)  // Vertically center text within the rectangle
	  .text("Approximate route across the Arctic Circle")
	  .attr("font-family", "Helvetica")
	  .attr("font-size", "16px")
	  .attr("fill", "#222222");

	// Fetch and display the route
	fetch(routeUrl)
	  .then(response => response.json())
	  .then(data => {
		svg.selectAll(".route")
		  .data(data.features)
		  .enter().append("path")
		  .attr("class", "route")
		  .attr("d", d => pathGenerator(d.geometry))
		  .attr("fill", "none")
		  .attr("stroke", "#008cce")
		  .attr("stroke-width", 5);
		setDataLoaded(true); // Set data loaded to true after route is drawn
	  });
  }, [routeUrl]);

  useEffect(() => {
	if (dataLoaded) { // Only convert to image if data is loaded
	  convertSvgToImage();
  	}
  }, [dataLoaded]); // Depend on dataLoaded to trigger image conversion

  function convertSvgToImage() {
	const svgElement = svgRef.current;
	if (svgElement) {
	  const scaleFactor = 2;  // Increase this factor for higher resolution; 2x is usually sufficient
	  const serializer = new XMLSerializer();
	  const svgString = serializer.serializeToString(svgElement);
	  const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
	  const DOMURL = window.URL || window.webkitURL || window;
	  const url = DOMURL.createObjectURL(svgBlob);

	  const image = new Image();
	  image.onload = function () {
		const canvas = document.createElement('canvas');
		canvas.width = width * scaleFactor;  // Increase canvas width
		canvas.height = height * scaleFactor;  // Increase canvas height
		const context = canvas.getContext('2d');
		context.scale(scaleFactor, scaleFactor);  // Scale up the drawing context

		// Draw rounded rectangle path for clipping
		const radius = 80; // Radius of the corners
		context.beginPath();
		context.moveTo(radius, 0);
		context.lineTo(canvas.width / scaleFactor - radius, 0);
		context.quadraticCurveTo(canvas.width / scaleFactor, 0, canvas.width / scaleFactor, radius);
		context.lineTo(canvas.width / scaleFactor, canvas.height / scaleFactor - radius);
		context.quadraticCurveTo(canvas.width / scaleFactor, canvas.height / scaleFactor, canvas.width / scaleFactor - radius, canvas.height / scaleFactor);
		context.lineTo(radius, canvas.height / scaleFactor);
		context.quadraticCurveTo(0, canvas.height / scaleFactor, 0, canvas.height / scaleFactor - radius);
		context.lineTo(0, radius);
		context.quadraticCurveTo(0, 0, radius, 0);
		context.closePath();
		context.clip();

		// Draw the image inside the clipped region
		context.drawImage(image, 0, 0, width, height);

		// Object.entries(types).forEach(([type, path]) => {
		// 	renderAircraftIcon(context, type, path, geoPath().projection(geoAzimuthalEqualArea()
		// 	  .scale(2150)
		// 	  .rotate([0, -90])
		// 	  .translate([canvas.width / (2 * scaleFactor), canvas.height / (2 * scaleFactor)])
		// 	  .clipAngle(90))
		// 	);
		// });

		// Convert canvas to data URL
		const imgURI = canvas.toDataURL('image/png');
		setImageUrl(imgURI);
		onImageReady(imgURI);
	  };
	  image.src = url;
	}
  }

  return (
	<div className="certificate-map-wrapper">
	  <svg className="certificate-map" ref={svgRef}>
		<g className="aircraft-icons"></g> {/* Group for aircraft icons */}
	  </svg>
	  
	  {imageUrl ? <img className="map-image" src={imageUrl} alt="Map" style={{ width: '100%', height: 'auto' }} /> : <p>Loading map...</p>}

	</div>
  );
}

export default CertificateMap;
