import React, { useState, useEffect } from 'react';
import moment from 'moment';

const DataModal = ({ onClose }) => {
  // State to hold the fetched airlines and routes data
  const [airlinesAndRoutes, setAirlinesAndRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const TimeComponent = ({ isoTime }) => {
	// Parse the ISO time and format it
	const readableDate = moment(isoTime).format('MMM Do, YYYY');
	return <div>{readableDate}</div>;
  };

  function formatDistance(distance) {
	  if (distance < 1) {
		  return `${(distance * 1000).toFixed(0)} m`;
	  } else {
		  return `${distance.toFixed(0)} km`;
	  }
  }

  const isRecent = (timestamp) => {
	const now = moment();
	const itemTime = moment(timestamp);
	return now.diff(itemTime, 'hours') <= 48;
  };

  useEffect(() => {
	  const fetchData = async () => {
		try {
		  setIsLoading(true); // Start loading
		  const response = await fetch('https://routes.ostia.goodcaesar.org/north/north-data.php');
		  const data = await response.json();
		  setAirlinesAndRoutes(data); // Store the data in state
		  setIsLoading(false); // End loading
		} catch (error) {
		  console.error('Error fetching data: ', error);
		  setIsLoading(false); // End loading in case of error too
		}
	  };
  
	  fetchData();
	}, []);
  
	if (isLoading) {
	  return (
		<div className="page data"> <div className="inside-page"><div className="loading"><h1>Finding winners<span className="ellipsis">...</span></h1></div></div></div>
	  );
	}

  return (
	<div className="page data">	
	  <div className="inside-page">
		<h1>Leaderboard</h1>
		<table>
		<thead>
			<tr>
			  <th scope="col">#</th>
			  <th scope="col">North Pole</th>
			  <th scope="col">Airline</th>
			  <th scope="col">Aircraft type</th>
			  <th scope="col">Route</th>
			  <th scope="col">Altitude</th>			 
			  <th scope="col">When (Zulu time)</th>
			</tr>
		  </thead>
		  <tbody>
		
		{airlinesAndRoutes.map((item, index) => (		
			<tr key={index} style={isRecent(item.closestPosition.timestamp) ? { backgroundColor: 'var(--color-light-grey)' } : {}}>
				<td data-label="" className="counter"><b>{index + 1}</b></td>
				<td data-label="Distance">{formatDistance(item.distance)}</td>
				<td data-label="Airline">{item.paintedAs}</td>
				<td data-label="Type">{item.type}</td>
				<td data-label="Route">{item.orig_iata} &#8212; {item.dest_iata}</td>				
				<td data-label="Altitude">{item.closestPosition.alt}</td>				
				<td data-label="When"><TimeComponent isoTime= {item.closestPosition.timestamp} /></td>
			 </tr>		
	
		))}
		
			</tbody>
		</table>
		 </div>
	</div>
  );
};

export default DataModal;
