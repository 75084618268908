import React, { useState, useEffect } from 'react';
import moment from 'moment';


const TimeTable = () => {
	const [timetableData, setTimetableData] = useState({});
	const currentHour = new Date().getHours(); // Get current hour

	useEffect(() => {
		fetch('https://polarradar.com/north-timetable-week.json')
			.then(response => response.json())
			.then(data => setTimetableData(data))
			.catch(error => console.error('Error fetching data:', error));
	}, []);

	const DurationDisplay = ({ minutes }) => {
	  // Create a duration object from the minutes
	  const duration = moment.duration(minutes, 'minutes');
	
	  // Format the duration as "X hours Y minutes"
	  // This will only display hours if the duration is 1 hour or more
	  const formattedDuration = `${duration.hours() > 0 ? `${duration.hours()}h` : ''}${duration.minutes()}m`;
	
	  return (
		<div>
		  {formattedDuration}
		</div>
	  );
	};

	const formatHour = (hour) => hour < 10 ? `0${hour}:00` : `${hour}:00`;

	return (
		<div className="page data">
		  <div className="inside-page">
		  
		  <h1>Time Table</h1>
		  
		<table>
			<thead>
				<tr>
					<th scope="col">Time (Z)</th>
					<th scope="col">Airline</th>
					<th scope="col">Route</th>
					<th scope="col">Duration</th>
					<th scope="col">Number</th>
					<th scope="col">Frequency</th>
					<th scope="col">Type</th>				
				</tr>
			</thead>
			<tbody>
				{Object.entries(timetableData).map(([hour, flights]) => {
					const flightEntries = Object.entries(flights);
					const isCurrentHour = parseInt(hour) === currentHour;
					const hourStyle = isCurrentHour ? { backgroundColor: 'var(--color-light-grey)' } : {}; // Highlight style

					return flightEntries.map(([flightNumber, details], index) => (
						<tr key={flightNumber}>
							
							{index === 0 && (
								<td data-label="Time" rowSpan={flightEntries.length} style={hourStyle}>{formatHour(hour)}</td>
							)}
							<td data-label="Airline">{details.airline_full}</td>
							<td data-label="Route">{details.route}</td>
							<td data-label="Duration"><DurationDisplay minutes={details.arctic_time} /></td>
							<td data-label="Number">{flightNumber}</td>							
							<td data-label="Frequency">{details.frequency}</td>
							<td data-label="Type">{details.type_full}</td>
						</tr>
					));
				})}
			</tbody>
		</table>
		  </div>
		</div>
	);
};

export default TimeTable;
