import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const AircraftTracks = ({ projection, showTracks, dataUrl, setCurrentTime, setAircraftCount }) => {
	const tracksRef = useRef();
	const [trackData, setTrackData] = useState(null);

	const fetchTracks = async () => {
		try {
			const response = await fetch(dataUrl);
			const data = await response.json();
			data.features.sort((a, b) => new Date(a.properties.most_recent_timestamp) - new Date(b.properties.most_recent_timestamp));
			setTrackData(data);
		} catch (error) {
			console.error('Error fetching track data:', error);
		}
	};

	useEffect(() => {
		const svg = d3.select(tracksRef.current);
	
		if (showTracks) {
			fetchTracks();
		} else {
			svg.selectAll("*").interrupt().remove(); // Stop animations and remove all elements
			setTrackData(null); // Optionally clear the track data
			setAircraftCount(0); // Reset aircraft count if needed
		}
	}, [showTracks, dataUrl]);

	useEffect(() => {
		const svg = d3.select(tracksRef.current);
		svg.selectAll("*").remove(); // Clear previous tracks

		if (trackData) {
			let count = 0;
			const lineGenerator = d3.geoPath().projection(projection);

			trackData.features.forEach((feature, index) => {
				const path = svg.append("path")
					.datum(feature)
					.attr("class", "aircraft-track")
					.attr("fill", "none")
					.attr("stroke", "var(--color-white)")
					.attr("stroke-width", 2.5)
					.attr("d", lineGenerator);

				const totalLength = path.node().getTotalLength();
				path.attr("stroke-dasharray", totalLength + " " + totalLength)
					.attr("stroke-dashoffset", totalLength)
					.transition()
					.delay(index * 900) // Adjust for your timing
					.duration(1000)
					.ease(d3.easeLinear)
					.attr("stroke-dashoffset", 0)
					.on('start', () => {
						count++;
						setAircraftCount(count);
						setCurrentTime(feature.properties.most_recent_timestamp);
					
						// Position of the label
						const midPoint = lineGenerator.centroid(feature);
					
						// Create a text element and append tspans for airline and route
						const text = svg.append("text")
							.attr("class", "aircraft-label")
							.attr("x", midPoint[0])
							.attr("y", midPoint[1])
							.style("opacity", 0);
					
						// Airline name
						text.append("tspan")
							.attr("x", midPoint[0])
							.attr("dy", "-0.5em") // Adjust vertical position as needed
							.text(feature.properties.painted_as || '');
					
						// Origin - Destination
						text.append("tspan")
							.attr("x", midPoint[0])
							.attr("dy", "1em") // This moves the next line down
							.text(`${feature.properties.orig_iata || ''} to ${feature.properties.dest_iata || ''}`);
					
						// Animate label opacity
						text.transition()
							.duration(500)
							.style("opacity", 1)
							.transition()
							.duration(1000)
							.style("opacity", 0)
							.remove();
					})
					
					.transition()
					.duration(500)
					.style("opacity", 0)
					.on('end', () => {
						count--;
						setAircraftCount(count);
					})
					.remove();
			});
		}
	}, [trackData, projection, setCurrentTime, setAircraftCount]);

	return <g ref={tracksRef} className="aircraft-tracks"></g>;
};

export default AircraftTracks;
