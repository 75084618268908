import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import worldData from '../data/world-110m.json';
import { feature } from 'topojson-client';

const GlobeRoute = () => {
  const countriesRef = useRef();

  useEffect(() => {
  	const svgElement = countriesRef.current;
  	const svg = d3.select(svgElement);
  	const width = svgElement.clientWidth;
  	const height = svgElement.clientHeight;
  	const projection = d3.geoOrthographic()
					   	.scale((width / 2) - 20)
					   	.translate([width / 2, height / 2])
					   	.rotate([0, 0]); // Initial rotation
  	const pathGenerator = d3.geoPath().projection(projection);
  	const graticule = d3.geoGraticule();
  
  const cities = [
	[-122.4194, 37.7749], // San Francisco
	[-0.1278, 51.5074],   // London
	[18.4241, -33.9249],  // Cape Town
	[174.7633, -36.8485], // Auckland
	[-122.4194, 37.7749]  // Back to San Francisco
  ];
  
  const line = {
	type: "LineString",
	coordinates: cities
  };
  
	const resize = () => {
	  const { width, height } = svgElement.getBoundingClientRect();
	  projection.fitSize([width, height], feature(worldData, worldData.objects.countries));
	  svg.selectAll(".country").attr("d", pathGenerator);
	  svg.select(".graticule").attr("d", pathGenerator);
	};
		  	
	// Render countries
	svg.selectAll(".country")
	  .data(feature(worldData, worldData.objects.countries).features)
	  .join("path")
	  .attr("class", "country")
	  .attr("d", pathGenerator)
	  .attr("fill-opacity", "0.5")
	  .attr("fill", "var(--color-white)");

	svg.append("path")
	  .datum(graticule())
	  .attr("class", "graticule")
	  .attr("d", pathGenerator)
	  .attr("fill", "none")
	  .attr("stroke", "#666")
	  .attr("stroke-opacity", "0.4")
	  .attr("stroke-width", 0.5);
	  
	svg.append("path")
	  .datum(line)
	  .attr("class", "route")
	  .attr("d", pathGenerator)
	  .attr("fill", "none")
	  .attr("stroke", "var(--color-brand-primary")
	  .attr("stroke-width", 2);  

  	window.addEventListener('resize', resize);
		resize(); // Call resize initially
	  
 	 let phi = 0; // Starting latitude
		const timer = d3.interval(() => {
		  phi += 0.5; // Increment latitude, adjust speed as needed
		  projection.rotate([0, phi]); // Update rotation
		  svg.selectAll(".country").attr("d", pathGenerator);
		  svg.select(".graticule").attr("d", pathGenerator);
		  svg.select(".route").attr("d", pathGenerator); // Update line position
		}, 50); // Update interval in milliseconds
	  
		return () => window.removeEventListener('resize', resize);
	  }, []);

  return (
	<svg ref={countriesRef} width={500} height={500} className="countries"></svg>
  );
};

export default GlobeRoute;
