import React from 'react';
import moment from 'moment';
import { useSpring, animated } from 'react-spring';
import aircraftTypes from '../components/AircraftTypes';

const NorthPoleCertificate = ({ selectedAircraft, showCertificate, distanceToNorthPole }) => {
  
  const certificateSpring = useSpring({
	opacity: showCertificate ? 1 : 0, // 0 or 1 based on showCertificate
	transform: showCertificate ? 'translateY(0px)' : 'translateY(10px)', // Adjust translateY as needed
  });
  
  const aircraftSVGPath = aircraftTypes[selectedAircraft.type]; 

 return showCertificate ? (
	<animated.div className="north-pole-certificate" style={certificateSpring}>
	  <div className="certificate-wrapper">
	  	
		<div className="coord">90&deg; Lat</div>			  
		<div className="coord">0&deg; Lon</div>	

		<div className="seal">
<svg width="634" height="634" viewBox="0 0 634 634" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1741_108)">
		<path d="M316.73 0C141.8 0 0 141.8 0 316.73C0 491.66 141.8 633.46 316.73 633.46C491.66 633.46 633.46 491.66 633.46 316.73C633.46 141.8 491.66 0 316.73 0ZM317.12 510.39C210.7 510.39 124.44 424.12 124.44 317.71C124.44 211.3 210.71 125.03 317.12 125.03C423.53 125.03 509.8 211.3 509.8 317.71C509.8 424.12 423.53 510.39 317.12 510.39Z" fill="black"/>
		<path d="M63.4791 176.27C65.9491 171.75 67.3591 170.3 69.6591 169.52C73.5091 168.16 77.8991 168.43 94.0491 177.26C110.199 186.09 112.789 189.65 113.729 193.63C114.309 195.99 113.849 197.96 111.379 202.48L103.889 216.17C101.419 220.69 99.9991 222.14 97.6991 222.92C93.8491 224.28 89.4591 224.01 73.3091 215.18C57.1591 206.35 54.5691 202.79 53.6291 198.81C53.0491 196.45 53.5091 194.48 55.9791 189.96L63.4691 176.27H63.4791ZM95.1491 214.76C95.9291 214.47 96.4991 214.08 97.7691 211.75L104.359 199.71C105.699 197.42 105.659 196.69 105.479 195.88C105.209 194.75 103.609 192.63 89.7891 185.07C75.9691 177.51 73.3191 177.31 72.2191 177.68C71.4391 177.97 70.8691 178.36 69.5991 180.69L63.0091 192.73C61.7391 195.06 61.7091 195.75 61.8891 196.56C62.1591 197.69 63.7591 199.81 77.5891 207.37C91.4091 214.93 94.0591 215.13 95.1491 214.76Z" fill="white"/>
		<path d="M96.0104 142.52L108.43 153.2L122.17 137.23L127.85 142.11L114.12 158.07L131.74 173.23L126.04 179.85L84.6504 144.24L107.6 117.57L113.28 122.45L96.0304 142.5L96.0104 142.52Z" fill="white"/>
		<path d="M134.35 103.86L144.52 116.7L161.03 103.62L165.68 109.49L149.17 122.57L163.61 140.79L156.76 146.22L122.85 103.42L150.42 81.5701L155.07 87.4401L134.35 103.86Z" fill="white"/>
		<path d="M193.66 119.33L166.27 72.1L173.83 67.72L201.23 114.95L193.67 119.33H193.66Z" fill="white"/>
		<path d="M247.18 92.5401C246.09 94.7101 244.46 95.9201 239.65 97.7501L225.51 103.14C220.7 104.97 218.68 105.16 216.42 104.27C212.6 102.8 209.44 99.7501 202.88 82.5501C196.32 65.3501 196.65 60.9701 198.52 57.3301C199.61 55.1601 201.24 53.9501 206.05 52.1201L220.19 46.7301C225 44.9001 227.02 44.7101 229.28 45.6001C233.1 47.0701 235.69 49.9201 239.2 58.6801L231.04 61.7901C228.37 55.4601 227.02 53.8901 225.94 53.4601C225.16 53.1701 224.47 53.1001 221.99 54.0501L209.6 58.7801C207.12 59.7301 206.65 60.2401 206.26 60.9701C205.73 62.0001 205.58 64.6501 211.19 79.3701C216.81 94.0901 218.69 95.9601 219.77 96.3901C220.55 96.6801 221.24 96.7501 223.72 95.8001L236.11 91.0801C238.62 90.2101 239.06 89.6201 239.45 88.8901C239.97 87.8501 240.07 86.1501 237.4 78.4801L245.63 75.3401C249.13 85.6101 249.05 88.9001 247.18 92.5301V92.5401Z" fill="white"/>
		<path d="M264.32 90.8699L253.24 37.4099L261.79 35.6399L272.87 89.0999L264.32 90.8699Z" fill="white"/>
		<path d="M315.55 69.3899L294.75 70.3699L290.72 85.9399L282.07 86.3499L296.87 32.8699C297.33 31.0499 297.78 30.7199 299.89 30.6199L307.45 30.2599C309.4 30.1699 309.96 30.4499 310.67 32.2199L330.46 84.0699L321.03 84.5199L315.55 69.3999V69.3899ZM296.66 62.9399L312.86 62.1699L304.22 38.0599L303.05 38.1199L296.65 62.9399H296.66Z" fill="white"/>
		<path d="M349.58 79.4701L375.69 83.6501L374.51 91.0401L339.77 85.4801L348.41 31.5701L357.03 32.9501L349.58 79.4701Z" fill="white"/>
		<path d="M435.839 112.89C433.659 113.67 431.109 113.42 427.149 111.56L410.569 103.75C406.689 101.92 404.659 100.37 403.839 98.08C402.759 95.07 403.249 92.37 407.359 83.27L415.189 86.96C412.439 93.17 412.059 94.54 412.349 95.37C412.569 95.99 412.969 96.61 415.089 97.61L428.989 104.15C431.039 105.11 431.769 105.2 432.599 104.9C433.539 104.56 434.569 102.73 436.529 98.56C438.229 94.96 438.689 93.8 438.269 92.66C437.969 91.83 437.369 91.29 435.459 90.21L420.599 81.49C417.169 79.44 415.379 77.74 414.599 75.56C413.549 72.65 414.299 70.16 417.349 63.67C420.339 57.32 422.439 54.51 425.759 53.32C427.939 52.54 430.559 52.82 434.299 54.58L449.539 61.76C453.419 63.59 455.449 65.14 456.269 67.43C457.349 70.44 456.779 72.93 452.909 81.89L445.079 78.2C447.659 72.35 448.049 70.98 447.749 70.15C447.529 69.53 447.129 68.91 445.009 67.91L432.519 62.03C430.539 61.1 429.809 61.01 428.979 61.31C428.049 61.65 426.969 63.56 425.209 67.3C423.579 70.76 423.089 71.99 423.499 73.13C423.799 73.96 424.469 74.36 426.349 75.51L441.209 84.23C444.459 86.11 446.319 87.84 447.099 90.02C448.109 92.82 447.539 95.49 444.389 102.19C441.169 109.04 439.139 111.7 435.809 112.9L435.839 112.89Z" fill="white"/>
		<path d="M477.871 145.95L448.221 123.35L481.321 79.9299L510.971 102.53L506.431 108.49L483.721 91.1799L474.601 103.15L493.401 117.48L488.861 123.43L470.061 109.1L459.711 122.69L482.421 140L477.881 145.96L477.871 145.95Z" fill="white"/>
		<path d="M517.16 166.44L503.63 150.61L488.86 156.98L483.24 150.4L534.57 129.31C536.29 128.56 536.83 128.71 538.2 130.31L543.12 136.06C544.39 137.54 544.5 138.16 543.55 139.8L514.72 187.22L508.59 180.05L517.18 166.45L517.16 166.44ZM510.67 147.57L521.21 159.9L534.96 138.3L534.2 137.41L510.66 147.57H510.67Z" fill="white"/>
		<path d="M530.061 199.5L542.771 222.69L536.201 226.29L519.301 195.44L567.181 169.2L571.381 176.86L530.071 199.5H530.061Z" fill="white"/>
		<path d="M92.7193 342.98C92.9793 348.12 92.5293 350.1 90.9793 351.97C88.4093 355.15 84.5293 357.21 66.1393 358.14C47.7593 359.06 43.6893 357.39 40.8093 354.49C39.0893 352.78 38.4393 350.86 38.1793 345.72L37.3993 330.14C37.1393 325 37.5893 323.02 39.1393 321.15C41.7093 317.98 45.5893 315.9 63.9793 314.98C82.3593 314.06 86.4293 315.73 89.3093 318.63C91.0393 320.34 91.6793 322.26 91.9393 327.4L92.7193 342.98ZM45.5893 326.76C45.0793 327.41 44.7993 328.05 44.9293 330.7L45.6193 344.41C45.6793 347.06 46.0993 347.67 46.6693 348.26C47.4893 349.08 49.9593 350.05 65.6993 349.26C81.4393 348.47 83.7993 347.26 84.5293 346.36C85.0393 345.71 85.3193 345.07 85.1893 342.42L84.4993 328.71C84.3693 326.06 84.0193 325.45 83.4493 324.86C82.6293 324.04 80.1593 323.07 64.4193 323.86C48.6793 324.65 46.3193 325.86 45.5893 326.76Z" fill="white"/>
		<path d="M90.6704 377.24L74.8504 381.49L80.3204 401.83L73.0904 403.78L67.6204 383.44L45.1704 389.48L42.9004 381.04L95.6304 366.86L104.77 400.83L97.5404 402.77L90.6704 377.23V377.24Z" fill="white"/>
		<path d="M119.149 445.89L78.5591 469.82L74.1191 462.29L114.709 438.37L105.999 423.59L112.449 419.79L134.319 456.88L127.869 460.68L119.159 445.89H119.149Z" fill="white"/>
		<path d="M128.561 531.56L122.571 525.2L140.451 508.35L122.581 489.39L104.701 506.24L98.7109 499.88L138.441 462.44L144.431 468.8L128.031 484.26L145.901 503.22L162.301 487.76L168.291 494.12L128.561 531.57V531.56Z" fill="white"/>
		<path d="M177.941 567.24L146.961 546.49L177.351 501.12L208.331 521.87L204.161 528.09L180.441 512.2L172.061 524.71L191.701 537.86L187.531 544.08L167.891 530.93L158.381 545.13L182.101 561.02L177.931 567.24H177.941Z" fill="white"/>
		<path d="M257.629 597.98L249.109 595.42C246.939 594.77 246.259 594.07 245.969 591.79L237.579 547.33L236.459 546.99L224.169 587.93L216.029 585.49L231.729 533.2L240.989 535.98C243.159 536.63 243.769 537.3 244.059 539.59L252.319 582.06L253.439 582.4L265.189 543.26L273.329 545.71L257.629 598V597.98Z" fill="white"/>
		<path d="M312.74 549.08C317.88 549.37 319.8 550.02 321.5 551.75C324.39 554.64 326.04 558.72 325.02 577.1C324 595.48 321.91 599.35 318.72 601.9C316.84 603.44 314.86 603.87 309.72 603.59L294.15 602.72C289.01 602.44 287.09 601.78 285.39 600.05C282.5 597.16 280.85 593.08 281.88 574.7C282.9 556.32 284.99 552.45 288.18 549.9C290.06 548.37 292.04 547.93 297.18 548.21L312.75 549.07L312.74 549.08ZM291.64 594.23C292.23 594.81 292.84 595.16 295.49 595.3L309.2 596.06C311.84 596.28 312.49 595.93 313.14 595.42C314.04 594.69 315.26 592.34 316.14 576.6C317.01 560.87 316.06 558.39 315.24 557.57C314.65 556.99 314.04 556.64 311.39 556.5L297.68 555.74C295.03 555.59 294.39 555.87 293.74 556.38C292.84 557.11 291.62 559.46 290.74 575.19C289.87 590.92 290.82 593.4 291.64 594.22V594.23Z" fill="white"/>
		<path d="M371.62 576.55C370.47 575.88 368.89 575.92 365.82 576.46L350.92 579.09L354.84 601.29L346.24 602.81L336.75 549.04L365.71 543.93C369.24 543.31 371.89 543.47 373.78 544.8C376.22 546.51 378.12 549.18 379.54 557.25C381.21 566.7 379.07 569.85 373.07 571.78L373.1 571.93C378.97 572 381.44 572.99 383.13 580.77C384.77 588.24 385.81 592.81 386.63 595.68L378.03 597.2C376.85 593.68 375.65 588.67 374.41 582.55C373.57 578.26 372.85 577.28 371.61 576.55H371.62ZM367.49 568.88C369.72 568.49 370.34 567.98 370.66 567.53C371.23 566.72 371.93 564.85 370.95 559.32C369.97 553.79 368.77 552.34 367.95 551.77C367.5 551.45 366.69 551.36 364.46 551.75L346.64 554.89L349.66 572.02L367.48 568.87L367.49 568.88Z" fill="white"/>
		<path d="M409.39 537.46L427.26 581.05L419.18 584.36L401.31 540.77L385.43 547.28L382.59 540.35L422.43 524.02L425.27 530.95L409.39 537.46Z" fill="white"/>
		<path d="M495.491 540.58L488.331 545.58L474.261 525.44L452.901 540.35L466.971 560.49L459.811 565.49L428.551 520.72L435.711 515.72L448.621 534.2L469.981 519.28L457.081 500.8L464.241 495.8L495.501 540.57L495.491 540.58Z" fill="white"/>
		<path d="M531.931 467.3C532.121 469.61 530.821 472.06 528.401 475.21L517.121 489.85L533.061 502.13L527.731 509.05L484.471 475.73L501.081 454.16C503.511 451.01 505.541 449.13 507.831 448.72C510.931 448.15 514.991 448.62 522.781 454.62C530.201 460.33 531.641 464.2 531.931 467.28V467.3ZM521.131 472.37C522.801 470.21 523.181 469.32 523.141 468.6C523.111 467.49 522.351 465.53 517.161 461.53C511.911 457.48 509.881 457.3 508.801 457.55C508.101 457.7 507.341 458.3 505.671 460.46L495.721 473.37L511.171 485.27L521.121 472.36L521.131 472.37Z" fill="white"/>
		<path d="M524.69 411.7C526.86 407.03 528.18 405.49 530.42 404.56C534.18 402.95 538.57 402.93 555.27 410.69C571.96 418.45 574.78 421.82 575.97 425.73C576.7 428.05 576.37 430.05 574.2 434.71L567.63 448.86C565.46 453.53 564.14 455.07 561.9 456C558.14 457.61 553.75 457.63 537.06 449.87C520.37 442.11 517.55 438.74 516.35 434.83C515.62 432.51 515.95 430.52 518.12 425.85L524.69 411.7ZM558.81 448.02C559.57 447.68 560.11 447.25 561.23 444.84L567.01 432.39C568.2 430.02 568.11 429.29 567.87 428.49C567.53 427.38 565.79 425.37 551.5 418.74C537.21 412.1 534.56 412.07 533.49 412.52C532.73 412.86 532.19 413.29 531.07 415.7L525.29 428.15C524.17 430.55 524.19 431.25 524.42 432.05C524.77 433.16 526.5 435.17 540.79 441.8C555.08 448.44 557.73 448.47 558.8 448.02H558.81Z" fill="white"/>
		<path d="M581.12 391.22L587.04 365.45L594.34 367.12L586.47 401.41L533.25 389.19L535.21 380.68L581.13 391.22H581.12Z" fill="white"/>
		<path d="M597.51 311.8L596.43 349.07L541.85 347.49L542.93 310.22L550.42 310.44L549.59 338.98L564.64 339.42L565.33 315.8L572.81 316.02L572.12 339.64L589.2 340.14L590.03 311.6L597.51 311.82V311.8Z" fill="white"/>
		<path d="M205.499 431.92C211.779 440 225.039 449.93 252.589 451.58C246.489 443.74 241.519 438.03 238.589 435.48C237.659 434.67 237.329 433.36 237.749 432.16C238.199 430.99 239.309 430.22 240.559 430.22H349.999C339.199 419.75 333.369 418.64 333.279 418.61C331.659 418.34 330.529 416.85 330.769 415.23C330.979 413.61 332.449 412.45 334.029 412.66C335.169 412.78 345.639 414.57 364.249 436.8C368.439 441.83 374.179 445.3 380.439 446.64C386.659 447.96 393.249 448.67 400.069 448.73C390.169 438.71 371.619 417.37 371.619 398.05C371.619 396.4 372.969 395.06 374.609 395.06C376.249 395.06 377.599 396.41 377.599 398.05C377.599 403.88 379.689 410.11 382.779 416.09C385.139 414.92 393.729 410.26 401.899 400.29C402.109 399.66 402.559 399.15 403.099 398.79C414.829 383.62 424.909 357.23 412.849 314.39C411.679 310.23 413.509 305.92 417.309 303.86C423.589 300.45 437.449 291.83 438.909 281.57C435.229 281.36 428.889 280.88 423.949 279.83C420.899 279.2 417.999 277.8 415.509 275.82C410.269 271.63 396.719 262.81 383.589 271.9C379.099 275.01 375.659 279.56 373.629 285.12C369.019 297.63 357.259 328.08 348.199 341.22C347.449 342.33 329.049 369.01 288.749 372.37C278.279 373.24 267.749 375.87 257.449 380.18C241.619 386.79 219.749 398.67 205.449 417.61C202.219 421.89 202.279 427.72 205.509 431.91L205.499 431.92ZM401.019 282.95C403.229 282.95 405.029 284.74 405.029 286.96C405.029 289.18 403.239 290.97 401.019 290.97C398.799 290.97 397.009 289.18 397.009 286.96C397.039 284.72 398.839 282.95 401.019 282.95Z" fill="black"/>
		<path d="M403.509 407.35C397.499 413.9 391.599 417.94 388.039 420.04C392.829 425.1 406.409 436.14 433.939 436.79C424.309 429.82 408.779 417.52 403.509 407.35Z" fill="black"/>
		<path d="M64.0797 275.8C69.1331 275.8 73.2297 271.703 73.2297 266.65C73.2297 261.597 69.1331 257.5 64.0797 257.5C59.0263 257.5 54.9297 261.597 54.9297 266.65C54.9297 271.703 59.0263 275.8 64.0797 275.8Z" fill="white"/>
		<path d="M324.321 220.91C327.921 222.29 331.641 222.25 335.301 222.34C362.331 222.94 389.371 223.01 416.391 221.99C418.641 221.9 420.891 221.7 423.051 220.99C423.781 220.75 424.691 220.56 424.751 219.63C424.811 218.68 423.921 218.42 423.201 218.14C421.071 217.31 418.851 217.02 416.581 216.93C409.131 216.61 401.681 216.46 394.231 216.67C392.021 216.73 389.991 216.34 387.991 215.31C380.051 211.23 372.591 206.32 364.901 201.83C359.741 198.82 354.651 195.71 349.491 192.69C348.361 192.03 346.701 191.41 345.941 192.55C345.251 193.59 346.931 194.33 347.701 195.04C354.691 201.49 361.731 207.89 368.741 214.32C369.291 214.82 370.281 215.28 370.031 216.1C369.721 217.09 368.551 216.87 367.761 216.87C363.001 216.85 358.231 216.51 353.481 216.71C348.261 216.93 344.021 214.91 340.181 211.75C336.511 208.73 332.911 205.61 329.421 202.39C327.861 200.95 326.251 200.09 324.121 200.19C323.371 200.23 322.401 199.8 321.961 200.59C321.401 201.58 322.471 202.12 322.991 202.72C325.811 206 328.621 209.29 330.821 213.03C332.761 216.34 331.731 218.9 328.061 220.04C326.841 220.42 325.571 220.64 324.331 220.93L324.321 220.91Z" fill="black"/>
		<path d="M240.06 221.62C278.609 221.62 309.86 220.823 309.86 219.84C309.86 218.857 278.609 218.06 240.06 218.06C201.51 218.06 170.26 218.857 170.26 219.84C170.26 220.823 201.51 221.62 240.06 221.62Z" fill="black"/>
		<path d="M567.439 275.8C572.492 275.8 576.589 271.703 576.589 266.65C576.589 261.597 572.492 257.5 567.439 257.5C562.386 257.5 558.289 261.597 558.289 266.65C558.289 271.703 562.386 275.8 567.439 275.8Z" fill="white"/>
		</g>
		<defs>
		<clipPath id="clip0_1741_108">
		<rect width="633.46" height="633.46" fill="white"/>
		</clipPath>
		</defs>
		</svg>

		</div>
	  
	    <h4>North Pole Certificate</h4>
	  
		<small>Passengers onboard flight <strong>{selectedAircraft.flight}</strong> have been awarded:</small>
		
		<h1>Conqueror of the top of the World</h1>	

		<small>On the <strong>{moment(selectedAircraft.eta).format('MMMM DD, YYYY')}</strong> they flew within <strong>{distanceToNorthPole.toFixed(0)}km</strong> of the North Pole onboard a <strong>{selectedAircraft.airline_full}</strong> {selectedAircraft.type_full} service from <strong>{selectedAircraft.orig_city}</strong> to <strong>{selectedAircraft.dest_city}</strong>.</small>
						
	  </div>
	</animated.div>
	) : null; // Return null when showCertificate is false
};
export default NorthPoleCertificate;
