import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import SiteTitle from '../components/SiteTitle';

const NavigationBar = ({ mapState, toggleMapState }) => {
  
  const navigate = useNavigate();
  const location = useLocation();
     
  return (
	<header>
	  <nav>
	  
	  	<SiteTitle mapState={mapState} toggleMapState={toggleMapState} />	  			  
		  
		<ul>			
		  
		  <li>
		  	<NavLink to="/">
		  		<span className="radar">
								
			<svg width="280" height="280" viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M265.809 147C262.177 213.332 207.239 266 140 266C70.4121 266 14 209.588 14 140C14 70.4121 70.4121 14 140 14C207.239 14 262.177 66.668 265.809 133H129V147H265.809ZM280 140C280 217.32 217.32 280 140 280C62.6802 280 0 217.32 0 140C0 62.6802 62.6802 0 140 0C217.32 0 280 62.6802 280 140Z" fill="black"/>
			</svg>


				</span>
				<b>Radar</b>
			</NavLink>
		  </li>
		  
		  <li>
		  	<NavLink to="/about">
			  <span>
			
			<svg width="280" height="280" viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M140 266C209.588 266 266 209.588 266 140C266 70.4121 209.588 14 140 14C70.4121 14 14 70.4121 14 140C14 209.588 70.4121 266 140 266ZM140 280C217.32 280 280 217.32 280 140C280 62.6801 217.32 0 140 0C62.6801 0 0 62.6801 0 140C0 217.32 62.6801 280 140 280Z" fill="black"/>
			<path d="M125.651 175.43V134.378C140.746 133.681 152.594 130.456 161.194 124.704C169.794 118.777 174.095 109.887 174.095 98.0334V94.3727C174.095 84.611 171.111 77.2898 165.143 72.4089C159.351 67.5281 151.365 65.0876 141.185 65.0876C130.654 65.0876 122.229 67.9638 115.91 73.7163C109.591 79.4687 105.203 86.79 102.746 95.6801L83 88.3588C84.7552 82.6064 87.2125 77.2026 90.3718 72.1474C93.5312 66.9179 97.4804 62.3857 102.219 58.5508C107.134 54.7158 112.838 51.6653 119.333 49.3992C126.002 47.1331 133.55 46 141.975 46C150.4 46 157.947 47.2202 164.617 49.6606C171.462 51.9268 177.254 55.2388 181.993 59.5967C186.732 63.7803 190.418 68.9226 193.051 75.0236C195.684 81.1247 197 87.923 197 95.4186C197 103.611 195.508 110.846 192.524 117.121C189.54 123.222 185.679 128.452 180.94 132.809C176.201 137.167 170.76 140.741 164.617 143.53C158.649 146.145 152.594 147.975 146.45 149.021V175.43H125.651ZM136.972 234C129.776 234 124.686 232.518 121.702 229.555C118.894 226.592 117.49 222.844 117.49 218.312V213.605C117.49 209.073 118.894 205.325 121.702 202.362C124.686 199.398 129.776 197.917 136.972 197.917C144.169 197.917 149.171 199.398 151.979 202.362C154.963 205.325 156.455 209.073 156.455 213.605V218.312C156.455 222.844 154.963 226.592 151.979 229.555C149.171 232.518 144.169 234 136.972 234Z" fill="black"/>
			</svg>


			  </span>
			  <b>About</b>
			</NavLink>
		</li>
		
		<li>
			<NavLink to="/certificates">
				<span>
				
			<svg width="291" height="291" viewBox="0 0 291 291" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M86.4293 242.438C90.1038 247.19 97.8623 253.03 113.982 254C110.413 249.389 107.505 246.031 105.791 244.532C105.246 244.055 105.053 243.285 105.299 242.579C105.562 241.891 106.212 241.438 106.943 241.438H170.978C164.659 235.281 161.247 234.628 161.195 234.61C160.247 234.452 159.586 233.575 159.726 232.623C159.849 231.67 160.709 230.988 161.634 231.111C162.301 231.182 168.427 232.235 179.316 245.308C181.767 248.266 185.126 250.307 188.789 251.095C192.428 251.871 196.284 252.289 200.274 252.324C194.482 246.431 183.628 233.881 183.628 222.519C183.628 221.549 184.418 220.761 185.377 220.761C186.337 220.761 187.127 221.555 187.127 222.519C187.127 225.948 188.35 229.612 190.158 233.128C191.539 232.44 196.565 229.7 201.345 223.837C201.468 223.466 201.731 223.166 202.047 222.954C208.911 214.033 214.808 198.513 207.752 173.319C207.067 170.873 208.138 168.338 210.362 167.126C214.036 165.121 222.146 160.052 223 154.018C220.847 153.894 217.137 153.612 214.247 152.994C212.462 152.624 210.765 151.801 209.308 150.636C206.242 148.172 198.314 142.985 190.632 148.331C188.005 150.16 185.992 152.836 184.804 156.105C182.107 163.463 175.226 181.37 169.925 189.098C169.486 189.75 158.72 205.441 135.14 207.417C129.014 207.929 122.852 209.475 116.826 212.01C107.563 215.897 94.7671 222.884 86.4 234.022C84.5101 236.539 84.5452 239.968 86.4351 242.432L86.4293 242.438ZM200.83 154.829C202.123 154.829 203.176 155.882 203.176 157.188C203.176 158.493 202.129 159.546 200.83 159.546C199.531 159.546 198.484 158.493 198.484 157.188C198.501 155.87 199.555 154.829 200.83 154.829Z" fill="black"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M200 0L229.5 25.5L262 53.5V291H29V0H181H195H200ZM181 14H43V277H248V68H181V14ZM241.131 54H195V14.1834L220.353 36.099L241.131 54Z" fill="black"/>
			</svg>

				</span>
				<b>Certificates</b>
			</NavLink>
		</li>
		
		<li>
			<NavLink to="/timetable">
				<span>
				
<svg width="291" height="291" viewBox="0 0 291 291" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_2164_91)">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M264.631 291H27.3694V279.847H264.631V291Z" fill="black"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M264.631 246.387L197.711 246.387L197.711 235.233L264.631 235.233L264.631 246.387Z" fill="black"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M264.631 197.718L27.3694 197.718L27.3694 186.564L264.631 186.564L264.631 197.718Z" fill="black"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M264.631 104.436L27.3694 104.436L27.3694 93.2822L264.631 93.2822L264.631 104.436Z" fill="black"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M264.631 11.1533L27.3694 11.1533L27.3694 0L264.631 -2.0742e-05L264.631 11.1533Z" fill="black"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M264.631 155.132L202.781 155.132L202.781 143.979L264.631 143.979L264.631 155.132Z" fill="black"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M264.631 56.7803L202.781 56.7803L202.781 45.627L264.631 45.6269L264.631 56.7803Z" fill="black"/>
				<path d="M168.916 170.236C165.455 170.236 162.405 169.435 159.766 167.835C157.149 166.234 155.094 163.811 153.601 160.567C152.13 157.322 151.395 153.234 151.395 148.302C151.395 143.349 152.13 139.25 153.601 136.005C155.094 132.761 157.149 130.349 159.766 128.77C162.405 127.169 165.455 126.369 168.916 126.369C172.333 126.369 175.351 127.169 177.968 128.77C180.607 130.349 182.662 132.761 184.133 136.005C185.625 139.25 186.372 143.349 186.372 148.302C186.372 153.234 185.625 157.322 184.133 160.567C182.662 163.811 180.607 166.234 177.968 167.835C175.351 169.435 172.333 170.236 168.916 170.236ZM168.916 163.227C171.944 163.227 174.291 161.951 175.956 159.399C177.622 156.825 178.455 153.126 178.455 148.302C178.455 143.479 177.622 139.78 175.956 137.206C174.291 134.61 171.944 133.312 168.916 133.312C165.866 133.312 163.497 134.61 161.81 137.206C160.144 139.78 159.312 143.479 159.312 148.302C159.312 153.126 160.144 156.825 161.81 159.399C163.497 161.951 165.866 163.227 168.916 163.227Z" fill="black"/>
				<path d="M127.851 170.236C124.39 170.236 121.34 169.435 118.701 167.835C116.084 166.234 114.029 163.811 112.537 160.567C111.066 157.322 110.33 153.234 110.33 148.302C110.33 143.349 111.066 139.25 112.537 136.005C114.029 132.761 116.084 130.349 118.701 128.77C121.34 127.169 124.39 126.369 127.851 126.369C131.269 126.369 134.286 127.169 136.904 128.77C139.543 130.349 141.597 132.761 143.068 136.005C144.561 139.25 145.307 143.349 145.307 148.302C145.307 153.234 144.561 157.322 143.068 160.567C141.597 163.811 139.543 166.234 136.904 167.835C134.286 169.435 131.269 170.236 127.851 170.236ZM127.851 163.227C130.879 163.227 133.226 161.951 134.892 159.399C136.558 156.825 137.39 153.126 137.39 148.302C137.39 143.479 136.558 139.78 134.892 137.206C133.226 134.61 130.879 133.312 127.851 133.312C124.801 133.312 122.433 134.61 120.745 137.206C119.08 139.78 118.247 143.479 118.247 148.302C118.247 153.126 119.08 156.825 120.745 159.399C122.433 161.951 124.801 163.227 127.851 163.227Z" fill="black"/>
				<path d="M93.0994 163.747C93.0994 162.276 93.5969 161.043 94.5919 160.048C95.5869 159.053 96.8631 158.556 98.4205 158.556C99.9779 158.556 101.276 159.053 102.314 160.048C103.352 161.043 103.871 162.276 103.871 163.747C103.871 165.196 103.352 166.408 102.314 167.381C101.276 168.333 99.9779 168.809 98.4205 168.809C96.8631 168.809 95.5869 168.322 94.5919 167.348C93.5969 166.375 93.0994 165.175 93.0994 163.747ZM93.0994 144.409C93.0994 142.938 93.5969 141.705 94.5919 140.71C95.5869 139.715 96.8631 139.218 98.4205 139.218C99.9779 139.218 101.276 139.715 102.314 140.71C103.352 141.705 103.871 142.938 103.871 144.409C103.871 145.858 103.352 147.07 102.314 148.043C101.276 148.995 99.9779 149.471 98.4205 149.471C96.8631 149.471 95.5869 148.984 94.5919 148.011C93.5969 147.037 93.0994 145.837 93.0994 144.409Z" fill="black"/>
				<path d="M54.3933 169.327V162.124C54.3933 159.766 54.7502 157.766 55.464 156.122C56.1778 154.478 57.2377 153.093 58.6437 151.969C60.0714 150.822 61.8559 149.838 63.9973 149.016L72.628 145.706C74.5098 144.949 75.8942 144.019 76.781 142.916C77.6895 141.813 78.1438 140.45 78.1438 138.828C78.1438 137.206 77.5489 135.843 76.3592 134.74C75.1912 133.615 73.5148 133.053 71.3301 133.053C69.2319 133.053 67.469 133.582 66.0414 134.642C64.6354 135.681 63.8243 137.206 63.608 139.217H55.1071C55.172 136.34 55.9183 133.95 57.3459 132.047C58.7735 130.143 60.6878 128.726 63.0888 127.796C65.4898 126.845 68.1937 126.369 71.2003 126.369C74.034 126.369 76.5972 126.909 78.89 127.991C81.1829 129.051 82.9999 130.543 84.341 132.469C85.7037 134.372 86.3851 136.6 86.3851 139.152C86.3851 141.315 85.9849 143.219 85.1846 144.863C84.4059 146.507 83.2162 147.945 81.6155 149.178C80.0148 150.411 77.9924 151.503 75.5481 152.455L66.8526 155.83C65.1654 156.479 63.9432 157.203 63.1862 158.004C62.4291 158.782 62.0506 159.81 62.0506 161.086V162.319H86.1255V169.327H54.3933Z" fill="black"/>
				<path d="M38.6605 126.953H44.4359V169.328H36.7786V136.103C35.6322 136.622 34.5074 137.022 33.4042 137.303C32.3011 137.585 31.1979 137.725 30.0947 137.725V130.392C31.5656 130.241 32.9932 129.906 34.3776 129.387C35.762 128.846 37.1896 128.035 38.6605 126.953Z" fill="black"/>
				<path d="M152.756 71.8841C149.295 71.8841 146.245 71.0837 143.606 69.4831C140.989 67.8824 138.934 65.4598 137.441 62.2152C135.971 58.9706 135.235 54.8824 135.235 49.9506C135.235 44.9972 135.971 40.8982 137.441 37.6536C138.934 34.409 140.989 31.9971 143.606 30.4181C146.245 28.8174 149.295 28.0171 152.756 28.0171C156.174 28.0171 159.191 28.8174 161.808 30.4181C164.447 31.9971 166.502 34.409 167.973 37.6536C169.466 40.8982 170.212 44.9972 170.212 49.9506C170.212 54.8824 169.466 58.9706 167.973 62.2152C166.502 65.4598 164.447 67.8824 161.808 69.4831C159.191 71.0837 156.174 71.8841 152.756 71.8841ZM152.756 64.8757C155.784 64.8757 158.131 63.5995 159.797 61.0471C161.462 58.4731 162.295 54.7742 162.295 49.9506C162.295 45.1269 161.462 41.4281 159.797 38.8541C158.131 36.2584 155.784 34.9605 152.756 34.9605C149.706 34.9605 147.337 36.2584 145.65 38.8541C143.985 41.4281 143.152 45.1269 143.152 49.9506C143.152 54.7742 143.985 58.4731 145.65 61.0471C147.337 63.5995 149.706 64.8757 152.756 64.8757Z" fill="black"/>
				<path d="M111.691 71.8841C108.231 71.8841 105.181 71.0837 102.542 69.4831C99.9244 67.8824 97.8695 65.4598 96.377 62.2152C94.9061 58.9706 94.1707 54.8824 94.1707 49.9506C94.1707 44.9972 94.9061 40.8982 96.377 37.6536C97.8695 34.409 99.9244 31.9971 102.542 30.4181C105.181 28.8174 108.231 28.0171 111.691 28.0171C115.109 28.0171 118.127 28.8174 120.744 30.4181C123.383 31.9971 125.438 34.409 126.909 37.6536C128.401 40.8982 129.147 44.9972 129.147 49.9506C129.147 54.8824 128.401 58.9706 126.909 62.2152C125.438 65.4598 123.383 67.8824 120.744 69.4831C118.127 71.0837 115.109 71.8841 111.691 71.8841ZM111.691 64.8757C114.72 64.8757 117.067 63.5995 118.732 61.0471C120.398 58.4731 121.231 54.7742 121.231 49.9506C121.231 45.1269 120.398 41.4281 118.732 38.8541C117.067 36.2584 114.72 34.9605 111.691 34.9605C108.642 34.9605 106.273 36.2584 104.586 38.8541C102.92 41.4281 102.087 45.1269 102.087 49.9506C102.087 54.7742 102.92 58.4731 104.586 61.0471C106.273 63.5995 108.642 64.8757 111.691 64.8757Z" fill="black"/>
				<path d="M76.9397 65.3949C76.9397 63.924 77.4372 62.6911 78.4322 61.696C79.4272 60.701 80.7034 60.2035 82.2608 60.2035C83.8182 60.2035 85.1161 60.701 86.1544 61.696C87.1926 62.6911 87.7118 63.924 87.7118 65.3949C87.7118 66.8441 87.1926 68.0555 86.1544 69.0288C85.1161 69.9806 83.8182 70.4565 82.2608 70.4565C80.7034 70.4565 79.4272 69.9698 78.4322 68.9964C77.4372 68.023 76.9397 66.8225 76.9397 65.3949ZM76.9397 46.0571C76.9397 44.5862 77.4372 43.3532 78.4322 42.3582C79.4272 41.3632 80.7034 40.8657 82.2608 40.8657C83.8182 40.8657 85.1161 41.3632 86.1544 42.3582C87.1926 43.3532 87.7118 44.5862 87.7118 46.0571C87.7118 47.5063 87.1926 48.7177 86.1544 49.691C85.1161 50.6428 83.8182 51.1187 82.2608 51.1187C80.7034 51.1187 79.4272 50.632 78.4322 49.6586C77.4372 48.6852 76.9397 47.4847 76.9397 46.0571Z" fill="black"/>
				<path d="M61.7911 28.6011H67.5665V70.9755H59.9092V37.7508C58.7628 38.27 57.638 38.6702 56.5348 38.9513C55.4317 39.2325 54.3285 39.3731 53.2253 39.3731V32.0404C54.6962 31.8889 56.1239 31.5537 57.5082 31.0345C58.8926 30.4938 60.3202 29.6826 61.7911 28.6011Z" fill="black"/>
				<path d="M38.6605 28.6011H44.4359V70.9755H36.7786V37.7508C35.6322 38.27 34.5074 38.6702 33.4042 38.9513C32.3011 39.2325 31.1979 39.3731 30.0947 39.3731V32.0404C31.5656 31.8889 32.9932 31.5537 34.3776 31.0345C35.762 30.4938 37.1896 29.6826 38.6605 28.6011Z" fill="black"/>
				<path d="M169.993 263.518C166.532 263.518 163.482 262.718 160.843 261.117C158.226 259.516 156.171 257.094 154.678 253.849C153.207 250.604 152.472 246.516 152.472 241.584C152.472 236.631 153.207 232.532 154.678 229.287C156.171 226.043 158.226 223.631 160.843 222.052C163.482 220.451 166.532 219.651 169.993 219.651C173.41 219.651 176.428 220.451 179.045 222.052C181.684 223.631 183.739 226.043 185.21 229.287C186.702 232.532 187.449 236.631 187.449 241.584C187.449 246.516 186.702 250.604 185.21 253.849C183.739 257.094 181.684 259.516 179.045 261.117C176.428 262.718 173.41 263.518 169.993 263.518ZM169.993 256.51C173.021 256.51 175.368 255.233 177.034 252.681C178.699 250.107 179.532 246.408 179.532 241.584C179.532 236.761 178.699 233.062 177.034 230.488C175.368 227.892 173.021 226.594 169.993 226.594C166.943 226.594 164.574 227.892 162.887 230.488C161.222 233.062 160.389 236.761 160.389 241.584C160.389 246.408 161.222 250.107 162.887 252.681C164.574 255.233 166.943 256.51 169.993 256.51Z" fill="black"/>
				<path d="M128.928 263.518C125.467 263.518 122.417 262.718 119.779 261.117C117.161 259.516 115.106 257.094 113.614 253.849C112.143 250.604 111.407 246.516 111.407 241.584C111.407 236.631 112.143 232.532 113.614 229.287C115.106 226.043 117.161 223.631 119.779 222.052C122.417 220.451 125.467 219.651 128.928 219.651C132.346 219.651 135.363 220.451 137.981 222.052C140.62 223.631 142.675 226.043 144.145 229.287C145.638 232.532 146.384 236.631 146.384 241.584C146.384 246.516 145.638 250.604 144.145 253.849C142.675 257.094 140.62 259.516 137.981 261.117C135.363 262.718 132.346 263.518 128.928 263.518ZM128.928 256.51C131.957 256.51 134.304 255.233 135.969 252.681C137.635 250.107 138.467 246.408 138.467 241.584C138.467 236.761 137.635 233.062 135.969 230.488C134.304 227.892 131.957 226.594 128.928 226.594C125.878 226.594 123.51 227.892 121.823 230.488C120.157 233.062 119.324 236.761 119.324 241.584C119.324 246.408 120.157 250.107 121.823 252.681C123.51 255.233 125.878 256.51 128.928 256.51Z" fill="black"/>
				<path d="M94.1765 257.029C94.1765 255.558 94.674 254.325 95.669 253.33C96.664 252.335 97.9402 251.838 99.4977 251.838C101.055 251.838 102.353 252.335 103.391 253.33C104.429 254.325 104.949 255.558 104.949 257.029C104.949 258.478 104.429 259.69 103.391 260.663C102.353 261.615 101.055 262.091 99.4977 262.091C97.9402 262.091 96.664 261.604 95.669 260.631C94.674 259.657 94.1765 258.457 94.1765 257.029ZM94.1765 237.691C94.1765 236.22 94.674 234.988 95.669 233.993C96.664 232.998 97.9402 232.5 99.4977 232.5C101.055 232.5 102.353 232.998 103.391 233.993C104.429 234.988 104.949 236.22 104.949 237.691C104.949 239.141 104.429 240.352 103.391 241.325C102.353 242.277 101.055 242.753 99.4977 242.753C97.9402 242.753 96.664 242.266 95.669 241.293C94.674 240.319 94.1765 239.119 94.1765 237.691Z" fill="black"/>
				<path d="M70.811 263.518C68.583 263.518 66.4308 263.215 64.3542 262.609C62.2777 262.004 60.4174 261.106 58.7735 259.916C57.1296 258.727 55.8209 257.256 54.8475 255.504C53.8958 253.73 53.4199 251.686 53.4199 249.371H61.4665C61.5531 250.842 61.9965 252.119 62.7968 253.2C63.5971 254.26 64.6787 255.082 66.0414 255.666C67.4258 256.228 69.0156 256.51 70.811 256.51C73.4499 256.51 75.5156 255.904 77.0082 254.693C78.5007 253.481 79.2469 251.945 79.2469 250.085C79.2469 248.874 78.9441 247.836 78.3384 246.97C77.7328 246.084 76.8027 245.413 75.5481 244.959C74.2935 244.483 72.6928 244.245 70.7461 244.245H65.1654V238.21H69.9025C72.3684 238.21 74.3043 237.68 75.7103 236.62C77.1163 235.56 77.8193 234.122 77.8193 232.305C77.8193 230.683 77.2136 229.331 76.0023 228.249C74.791 227.146 72.974 226.594 70.5514 226.594C68.583 226.594 66.8201 226.994 65.2627 227.795C63.7269 228.595 62.7427 229.99 62.3101 231.98H54.2635C54.5663 229.103 55.4316 226.757 56.8592 224.94C58.3084 223.123 60.1795 221.792 62.4724 220.949C64.7652 220.083 67.3501 219.651 70.2269 219.651C73.2769 219.651 75.9915 220.148 78.3709 221.143C80.7503 222.138 82.6213 223.523 83.984 225.296C85.3684 227.049 86.0606 229.082 86.0606 231.396C86.0606 233.538 85.4766 235.409 84.3085 237.009C83.1405 238.589 81.41 239.767 79.1171 240.546C81.6696 241.411 83.7137 242.698 85.2494 244.407C86.7852 246.116 87.5531 248.247 87.5531 250.799C87.5531 253.287 86.8501 255.493 85.4441 257.418C84.0598 259.322 82.113 260.814 79.6038 261.896C77.0947 262.977 74.1637 263.518 70.811 263.518Z" fill="black"/>
				<path d="M38.6605 220.235H44.4359V262.61H36.7786V229.385C35.6322 229.904 34.5074 230.304 33.4042 230.586C32.3011 230.867 31.1979 231.007 30.0947 231.007V223.675C31.5656 223.523 32.9932 223.188 34.3776 222.669C35.762 222.128 37.1896 221.317 38.6605 220.235Z" fill="black"/>
				</g>
				<defs>
				<clipPath id="clip0_2164_91">
				<rect width="291" height="291" fill="white"/>
				</clipPath>
				</defs>
				</svg>
		
				</span>
				<b>Time Table</b>
			</NavLink>
		</li>
			
		</ul>	 
	  </nav>
	</header>
  );
};

export default NavigationBar;