export const articleContent = [

	{ type: 'text', content: "<p>Airlines have been flying over the Arctic Circle and crossing the North Pole since the 1950s. Crossing this airspace was the only way of connecting Europe with South East Asia as the Soviet airspace was off-limits, almost impenetrable. Those that <span>accidentally breached it</span>, learned the hard way just how cold the Cold War was.</p><p>Planes would take off from London, Paris, Copenhagen and other European cities, head north across the Arctic Circle and come within touching distance of the North Pole. They would then traverse northern Canada and land in Anchorage, Alaska. There on the ground in the most northerly American state, their planes would refuel before making their way across the Pacific Ocean to their final destination. The return leg happened in reverse. </p><p>This became a common route for airlines such as BOAC, Scandinavian, KLM and others and was popularly known as the Polar Route. This routing continued until Boeing made the 747-400, a plane that had the range to complete the Polar Route without stopping. It was certified in 1989 &#8212; almost perfectly synchronized with the fall of the Iron Curtain, which opened the Russian airspace for foreign airlines and rendered the Polar Route route obsolete.</p>", note: 'Read the story of <a href="https://en.wikipedia.org/wiki/Korean_Air_Lines_Flight_007">Korean Airlines Flight 007</a>' },
	  
	{ 
	  type: 'big-single-image',
	  content: [
		{ imageUrl: 'img/sas-polar-1958-map.jpg', caption: 'SAS Polar Route Map, 1958' },
	  ],
	},

  { type: 'headline', content: 'Cold Again' },

  { type: 'text', content: '<p>Today the Russian airspace is again off-limits to Western airlines. This restriction has led to flights being rerouted between Japan and Europe and many flights now pass over the Arctic Circle. This detour adds around two hours to the journey compared to the previous routes that flew over the Russian peninsula.</p><p>Airlines such as Finnair, Japan Airlines, ANA, KLM, Swiss, and SAS have reverted to using the Polar Route. Their resumption of the Polar Route takes place on the return leg from South Korea and Japan. For the outbound leg they fly a longer route through Kazakhstan eastwards carefully avoiding Russia. The Arctic is also being heavily used by Middle Eastern Airlines connecting Doha, Dubai and Abu Dhabi with North America.</p>', note: 'Explore our <a href="/vintage">vintage mode</a> to see how planes used to fly over the Arctic.' },
  
  { 
    type: 'images',
    content: [
    { imageUrl: 'img/lufthansa-polar-route.jpg', caption: 'Lufthansa, 1986' },
    { imageUrl: 'img/klm-world-map-polar.jpg', caption: 'KLM, 1962' },
    { imageUrl: 'img/alitalia-polar-route.jpg', caption: 'Alitalia, 1966' },
    { imageUrl: 'img/japan-airlines-polar.jpg', caption: 'Japan Air Lines, 1967' },   
    ],
    },
    
  { type: 'headline', content: 'Polar Radar' },  
    
  { type: 'text', content: "<p>Polar Radar monitors air traffic over the North and South Poles, utilizing aircraft positioning data pulled from ADSB transponders. These transponders, which are installed on most modern aircraft, transmit positioning data to ground and satelite receivers. This data is the foundation of most airline radar applications, such as Flight Radar 24. They provide a map view showcasing the vast majority of the world's air traffic. However, these applications often have limitations when it comes to the polar regions, where coverage can be off-limits, distorted, or challenging to navigate. To address this gap, Polar Radar employs a polar projection approach, enabling the tracking of aircraft crossing the polar regions.</p><p>Our map not only includes geographic features but also depicts the Arctic ice sheet, this vast but shrinking expanse of ice covering most of the area. The airspace above is often relatively quiet. To provide users with insights into air traffic patterns, our Arctic traffic timetable is based on data from the past seven days of crossings, offering a reasonable forecast of expected air traffic and its timing.</p><p>The polar regions' remoteness poses unique challenges in tracking aircraft. The positioning data we receive from aircraft in both the North and South Poles can sometimes be incomplete, inaccurate, or entirely absent. Consequently, it's not uncommon for aircraft to temporarily disappear and then reappear.</p>", note: "Tracking across the Polar regions can be tricky. Planes sometimes point in the wrong direction. Their tracks zig-zagging. In reality they fly pretty smoothly." },
  
  { 
	  type: 'images',
	  content: [
		{ imageUrl: 'img/jal-north-pole-fan-front.jpg', caption: 'Japan Airlines North Pole Hand Fan  painted front, 1960s' },
		{ imageUrl: 'img/jal-north-pole-fan-back.jpg', caption: 'Japan Airlines North Pole Hand Fan, signed by Captain W. A. Black, 1960s' },
		{ imageUrl: 'img/Misc-4069.jpg', caption: 'Proclamation certificate, Canadian Airlines, 1940s' },
		{ imageUrl: 'img/sas-certificate.jpg', caption: 'Scandinavian Airlines Systems, 1957' },
		{ imageUrl: 'img/FODSmtDXMAE14Xa.jpeg', caption: 'The Northern Route Diploma, Finnair, 2022' },
	  ],
    },
	
  { type: 'headline', content: 'Tokens and Gifts' },  

  { type: 'text', content: '<p>When Finnair resumed operations over the North Pole, they also revived an old tradition of giving diplomas to passengers who flew over this geographic point. This gesture is a way to commemorate the crossing of the North Pole. Similar traditions have been observed by other airlines: Japan Airlines used to give out hand fans autographed by the Captain, SAS distributed certificates, and Canadian Airlines handed out proclamations, all celebrating the crossing of this invisible point on a sheet of ice.</p><p>Similar traditions exist for the crossing of the Equator and the International Date Line. To the best of my knowledge, there has been no such tradition for flights crossing the South Pole perhaps because there has been so few.</p>' },
          
  { type: 'film', content:'9phjmBEhYsw' },

{ type: 'headline', content: 'Antarctica' },  

  { type: 'text', content: '<p>There is no scheduled air traffic over or to Antarctica. It has no paved runways. The only flights operating there are local traffic, helicopters based at research stations, the occasional DC-3, supply flights, and tourist visits, which often originate from Auckland or Cape Town during the Antarctic summer months, from October to February.</p><p>When Pan American looked to celebrate its 50th birthday in style, they organized a special flight. They set out to circumnavigate the globe, not westwards or eastwards as is the most common route for circumnavigation but by going north, then south. The anniversary flight took off from San Francisco to London Heathrow then onwards to Cape Town, South Africa. From there it took to the sky towards Auckland, New Zealand on a route that no one ever flies. Straight across the width of Antarctica and close to the South Pole. To my knowledge, this has never been repeated. Their anniversary journey was finished when they took off from Auckland and flew straight back to San Francisco and completing the circle.</p>', note: 'Polar Radar is a project by <a href="https://directionoftravel.com">Direction of Travel</a>'},
 
  ];