import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SiteTitle = ({ mapState, toggleMapState }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getClassForMode = () => {
	  switch (location.pathname) {
		case '/south':
		  return 'south';
		case '/vintage':
		  return 'vintage';
		default:
		  return 'north';
	  }
	};
  
  	console.log("Current mapState from site:", mapState);
    
	const handleTitleClick = () => {
			
	  document.body.classList.remove('north', 'south', 'vintage');
	
		if (location.pathname === '/') {			
			document.body.classList.add('south');
			navigate(location.pathname === '/south' ? '/' : '/south');
		} else {
			document.body.classList.add('north');
			navigate('/');
		}	  
	};

  return (
	  <div className={`site-title ${getClassForMode()}`} onClick={handleTitleClick}>
	  
		<div className="logo-wrapper">

<svg className="north-south" width="141" height="141" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M140.89 70.45C140.89 31.5471 109.353 0.010005 70.45 0.0100067C31.5471 0.0100084 0.010005 31.5471 0.0100067 70.45C0.0100084 109.353 31.5471 140.89 70.45 140.89C109.353 140.89 140.89 109.353 140.89 70.45Z" fill="white"/>
		<path d="M31.8902 24.9801L30.3802 26.44L28.1302 24.11L33.8302 18.5901L36.0802 20.92L34.5502 22.4L44.9202 33.11L42.1502 35.8C41.4402 36.49 41.0802 36.52 40.1802 36.19L28.2602 31.75L28.0702 31.93L34.5502 38.61L36.1102 37.0901L38.3802 39.44L32.5902 45.05L30.3202 42.7001L31.8802 41.19L23.7802 32.83L22.2202 34.35L19.9702 32.0201L24.6202 27.51C25.3102 26.84 25.6802 26.8101 26.5902 27.1201L37.6002 31.25L37.7902 31.07L31.8902 24.9801Z" fill="black"/>
		<path d="M51.1001 10.74C52.7201 10.13 53.4401 10.11 54.2401 10.47C55.5001 11.02 56.5301 12.43 58.4901 17.65C60.4401 22.84 60.6001 24.58 60.0301 25.85C59.6701 26.65 59.1201 27.11 57.4901 27.72L51.7701 29.88C50.1701 30.48 49.4301 30.51 48.6301 30.15C47.3601 29.58 46.3301 28.16 44.3801 22.97C42.4201 17.76 42.2601 16.02 42.8401 14.77C43.2001 13.97 43.7801 13.5 45.3801 12.9L51.1001 10.74ZM50.6501 26.45C50.8801 26.56 51.1001 26.53 51.8501 26.25L55.0801 25.04C55.8101 24.77 56.0101 24.64 56.0901 24.41C56.2401 24.13 56.0901 23.47 54.4801 19.17C52.8501 14.85 52.5301 14.28 52.2301 14.15C52.0301 14.06 51.7901 14.09 51.0701 14.37L47.8501 15.59C47.1001 15.87 46.9201 15.99 46.8101 16.2C46.7001 16.49 46.8101 17.14 48.4301 21.46C50.0601 25.78 50.4101 26.34 50.6701 26.46L50.6501 26.45Z" fill="black"/>
		<path d="M78.5102 22.78L80.4302 22.82L80.3602 26.11L76.6302 26.03C75.5402 26.01 75.1602 25.82 74.8502 25.5C74.4702 25.1 74.2802 24.42 74.2402 21.1C74.2202 19.7 74.1002 19.31 73.8202 18.99C73.4902 18.67 73.1302 18.56 72.2202 18.54L69.3702 18.48L69.2802 22.6L71.5102 22.65L71.4402 25.92L62.9902 25.74L63.0602 22.47L65.2402 22.52L65.4902 10.88L63.3102 10.83L63.3802 7.58999L75.0702 7.83999C76.4402 7.86999 77.3502 8.11999 77.9502 8.75999C78.6902 9.52999 78.9502 10.47 78.9002 12.95C78.8302 16.16 78.0102 16.85 75.9102 17.17L75.9102 17.22C77.7702 17.65 78.4502 18.31 78.4702 20.96C78.4602 21.5 78.4902 22.23 78.5102 22.78ZM73.0902 15.53C73.8402 15.55 74.1002 15.5 74.2602 15.35C74.5002 15.12 74.5902 14.73 74.6102 13.33C74.6302 11.93 74.5702 11.51 74.3402 11.25C74.1902 11.12 73.9302 11.03 73.1802 11.02L69.5302 10.94L69.4302 15.45L73.0902 15.53Z" fill="black"/>
		<path d="M93.3502 28.27L92.0602 31.27L84.0602 27.82L85.3602 24.82L87.5002 25.74L92.1402 15.01L89.4302 13.84L88.0602 17L84.9202 15.64L87.5502 9.55001L102.97 16.21L100.34 22.3L97.2002 20.94L98.5702 17.77L95.8602 16.6L91.2302 27.33L93.3702 28.26L93.3502 28.27Z" fill="black"/>
		<path d="M110.23 42.9801L111.72 44.57L109.34 46.8L103.68 40.7701L106.06 38.54L107.46 40.03L110.73 36.96L106.56 32.5201L103.29 35.5901L104.71 37.1L102.33 39.33L96.6601 33.28L99.0401 31.0501L100.53 32.64L109.02 24.68L107.53 23.0901L109.89 20.87L115.56 26.92L113.2 29.14L111.78 27.6301L108.83 30.4L113 34.8401L115.95 32.07L114.55 30.58L116.91 28.36L122.57 34.39L120.21 36.61L118.72 35.0201L110.23 42.9801Z" fill="black"/>
		<path d="M98.63 106.65C98.63 105.88 98.98 105.07 99.99 104.03L104.55 99.3601C105.62 98.2701 106.38 97.7801 107.24 97.7501C108.3 97.7401 109.28 98.3301 111.62 100.58L108.87 103.4C107.55 102.19 107.2 101.95 106.98 101.96C106.76 101.96 106.52 102.09 105.98 102.65L103.43 105.27C102.91 105.81 102.73 106.07 102.71 106.3C102.73 106.57 103.05 106.96 103.83 107.72C104.48 108.35 104.74 108.57 105.07 108.57C105.29 108.57 105.6 108.36 106.16 107.9L110.28 104.5C111.24 103.7 111.95 103.34 112.74 103.32C113.75 103.32 114.67 103.9 116.43 105.62C118.08 107.23 118.77 108.38 118.79 109.55C118.79 110.32 118.42 111.15 117.46 112.13L113.22 116.47C112.13 117.58 111.37 118.07 110.56 118.08C109.46 118.09 108.59 117.57 106.27 115.38L109 112.58C110.21 113.68 110.54 113.9 110.79 113.9C111.01 113.9 111.21 113.77 111.72 113.25L114.07 110.84C114.54 110.36 114.7 110.15 114.7 109.93C114.7 109.67 114.38 109.29 113.68 108.6C113.03 107.97 112.77 107.75 112.46 107.77C112.22 107.75 112 107.94 111.37 108.44L107.25 111.84C106.29 112.64 105.61 113 104.84 113.01C103.85 113.02 102.85 112.45 101.09 110.73C99.31 108.99 98.62 107.85 98.6 106.67L98.63 106.65Z" fill="black"/>
		<path d="M93.6102 128.87C92.0302 129.59 91.3202 129.65 90.4902 129.34C89.2002 128.87 88.0802 127.53 85.7702 122.46C83.4802 117.41 83.2102 115.68 83.7002 114.38C84.0102 113.56 84.5302 113.07 86.1102 112.35L91.6802 109.82C93.2402 109.11 93.9702 109.04 94.8002 109.35C96.1002 109.84 97.2302 111.18 99.5202 116.23C101.82 121.31 102.09 123.03 101.59 124.31C101.28 125.13 100.74 125.63 99.1802 126.34L93.6102 128.87ZM93.0302 113.16C92.7902 113.07 92.5802 113.11 91.8502 113.44L88.7102 114.87C88.0002 115.19 87.8102 115.34 87.7402 115.56C87.6102 115.85 87.8002 116.5 89.6902 120.68C91.6002 124.88 91.9602 125.43 92.2702 125.54C92.4802 125.62 92.7102 125.57 93.4202 125.25L96.5602 123.83C97.2902 123.5 97.4602 123.37 97.5602 123.15C97.6502 122.85 97.5002 122.21 95.6002 118.01C93.6902 113.81 93.3102 113.27 93.0302 113.17L93.0302 113.16Z" fill="black"/>
		<path d="M71 130L71.09 133.24L62.82 133.48L62.73 130.24L64.85 130.18L64.63 122.69C64.51 118.42 64.81 117.29 65.77 116.3C66.58 115.45 67.71 115.18 70.07 115.11L73.31 115.02C75.67 114.95 76.79 115.15 77.61 115.93C78.62 116.89 78.99 117.96 79.12 122.27L79.34 129.76L81.46 129.7L81.55 132.94L73.23 133.18L73.14 129.94L75.26 129.88L75.03 121.98C74.96 119.49 74.76 118.98 74.47 118.7C74.2 118.45 73.73 118.38 72.28 118.43L71.09 118.46C69.69 118.5 69.23 118.59 68.98 118.86C68.68 119.18 68.56 119.65 68.63 122.16L68.86 130.06L70.98 130L71 130Z" fill="black"/>
		<path d="M48.4602 113.25L49.6903 110.22L57.7603 113.49L56.5403 116.52L54.3803 115.64L50.0003 126.48L52.7402 127.59L54.0302 124.39L57.2003 125.67L54.7103 131.82L39.1403 125.52L41.6303 119.37L44.8003 120.65L43.5103 123.85L46.2503 124.96L50.6402 114.12L48.4803 113.25L48.4602 113.25Z" fill="black"/>
		<path d="M31.2203 98.91L29.6903 97.36L32.0203 95.07L37.8103 100.97L35.4803 103.26L34.0503 101.8L30.8502 104.94L35.1203 109.29L38.3203 106.15L36.8703 104.67L39.2003 102.38L45.0103 108.3L42.6803 110.59L41.1503 109.04L32.8403 117.19L34.3603 118.74L32.0503 121.01L26.2402 115.09L28.5503 112.82L30.0003 114.3L32.8903 111.47L28.6203 107.12L25.7303 109.95L27.1603 111.41L24.8502 113.68L19.0603 107.78L21.3703 105.51L22.8902 107.06L31.2003 98.91L31.2203 98.91Z" fill="black"/>
		</svg>

		<svg className="pink-circle" width="278" height="278" viewBox="0 0 278 278" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M138.82 277.64C215.488 277.64 277.64 215.488 277.64 138.82C277.64 62.1518 215.488 0 138.82 0C62.1518 0 0 62.1518 0 138.82C0 215.488 62.1518 277.64 138.82 277.64Z" fill="var(--color-brand-primary)"/>
		
		<path d="M86.7401 52.09C86.5301 53.38 85.5601 54.4 83.7901 55.68L75.1601 61.94L82.0101 71.39L79.3201 73.34L62.0601 49.55L73.3801 41.34C75.1501 40.06 76.4201 39.45 77.7101 39.65C79.3601 39.85 81.0701 40.64 84.0101 44.68C86.8401 48.59 87.0601 50.46 86.7401 52.09ZM81.5401 53.79C82.8301 52.85 83.3101 52.3 83.4501 51.63C83.6001 50.9 83.4901 49.68 81.2401 46.59C78.9701 43.46 77.8701 43.02 77.1301 42.93C76.4501 42.85 75.7701 43.14 74.4801 44.07L66.4201 49.91L73.4701 59.63L81.5301 53.79H81.5401Z" fill="white"/>
		<path d="M105.81 26.27C108.36 25.42 109.46 25.41 110.56 25.93C112.49 26.8 114 28.55 117.14 37.99C120.28 47.44 120.12 49.75 119.1 51.59C118.53 52.67 117.64 53.32 115.09 54.16L106.48 57.02C103.97 57.85 102.87 57.87 101.73 57.36C99.85 56.48 98.29 54.74 95.15 45.3C92.01 35.86 92.21 33.53 93.19 31.7C93.8 30.61 94.69 29.96 97.2 29.13L105.81 26.27ZM103.15 54.46C103.67 54.69 104.23 54.77 105.9 54.21L113.87 51.56C115.56 51.04 115.94 50.6 116.22 50.11C116.69 49.25 116.81 47.61 113.96 39.05C111.11 30.48 110.03 29.25 109.14 28.83C108.62 28.6 108.06 28.52 106.39 29.08L98.42 31.73C96.75 32.29 96.35 32.69 96.07 33.18C95.64 34.03 95.48 35.68 98.33 44.24C101.18 52.8 102.29 54.03 103.15 54.46Z" fill="white"/>
		<path d="M130.76 48.44L145.7 47.84L145.81 50.69L127.56 51.42L126.38 22.04L129.7 21.91L130.77 48.43L130.76 48.44Z" fill="white"/>
		<path d="M169.15 46.66L157.23 43.58L152.17 51.56L148.96 50.73L165.35 25.6C165.81 24.81 166.11 24.67 167.17 24.94L169.73 25.6C170.75 25.86 170.94 26.13 171 27.06L173.15 56.99L169.69 56.1L169.13 46.67L169.15 46.66ZM158.83 41.09L168.96 43.71L168.03 28.2L167.18 27.98L158.84 41.1L158.83 41.09Z" fill="white"/>
		<path d="M197.24 57.5501C197.02 56.7201 196.38 56.0701 194.88 55.1401L186.54 49.9301L179.77 60.7601L176.95 59.0001L192.53 34.0601L205.14 41.9401C206.89 43.0301 207.85 44.0301 208.16 45.2701C208.52 46.8301 208.33 48.4901 206.06 52.1301C203.37 56.4401 201.38 56.9301 198.55 55.9101L198.53 55.9501C200.63 58.0601 201.08 59.3301 198.76 63.4301C197.04 66.4201 195.77 68.6901 195.01 70.3001L192.23 68.5601C193.03 66.7301 194.33 64.3201 195.86 61.7201C197.35 59.1801 197.46 58.3601 197.22 57.5701L197.24 57.5501ZM197.07 53.2301C198.35 54.0301 199.04 54.1201 199.62 53.9801C200.31 53.8201 201.42 53.3201 203.18 50.5101C204.96 47.6601 204.96 46.4701 204.8 45.7801C204.67 45.2001 204.15 44.6801 202.97 43.9501L193.85 38.2501L188.02 47.5801L197.07 53.2301Z" fill="white"/>
		<path d="M85.01 231.07C84.81 230.23 84.2 229.57 82.73 228.58L74.57 223.11L67.46 233.71L64.7 231.86L81.07 207.44L93.42 215.72C95.13 216.87 96.06 217.89 96.33 219.14C96.64 220.71 96.39 222.37 94 225.92C91.17 230.14 89.17 230.57 86.37 229.45L86.35 229.49C88.38 231.66 88.79 232.94 86.35 236.97C84.54 239.9 83.19 242.13 82.38 243.71L79.66 241.89C80.52 240.09 81.9 237.73 83.51 235.17C85.08 232.68 85.22 231.87 85 231.06L85.01 231.07ZM84.97 226.75C86.23 227.59 86.91 227.7 87.5 227.58C88.2 227.44 89.32 226.98 91.17 224.22C93.04 221.43 93.08 220.24 92.94 219.54C92.82 218.96 92.33 218.42 91.17 217.65L82.24 211.66L76.11 220.8L84.97 226.74V226.75Z" fill="white"/>
		<path d="M114.31 245.89L102.48 242.47L97.2 250.3L94.01 249.38L111.1 224.72C111.59 223.94 111.89 223.81 112.94 224.11L115.48 224.84C116.49 225.13 116.67 225.4 116.71 226.33L118.02 256.31L114.59 255.32L114.3 245.88L114.31 245.89ZM104.15 240.03L114.2 242.93L113.7 227.4L112.85 227.16L104.15 240.04V240.03Z" fill="white"/>
		<path d="M143.72 228C146.87 227.92 148.56 228.42 149.98 229.65C151.83 231.33 153.01 234.57 153.21 242.47C153.41 250.36 152.4 253.67 150.64 255.43C149.29 256.72 147.62 257.31 144.47 257.39L131.2 257.73L130.45 228.34L143.72 228ZM147.56 231.81C146.66 230.99 145.81 230.8 143.8 230.86L133.85 231.11L134.46 254.79L144.41 254.54C146.43 254.49 147.3 254.26 148.12 253.39C149.27 252.27 150.05 249.9 149.86 242.59C149.67 235.2 148.77 232.87 147.57 231.81H147.56Z" fill="white"/>
		<path d="M179.49 240.85L167.83 244.79L167.96 254.24L164.82 255.3L164.8 225.29C164.76 224.37 164.93 224.09 165.96 223.75L168.47 222.9C169.47 222.56 169.77 222.68 170.33 223.42L188.5 247.3L185.12 248.44L179.5 240.85H179.49ZM167.8 241.82L177.71 238.47L168.44 225.99L167.6 226.27L167.79 241.81L167.8 241.82Z" fill="white"/>
		<path d="M206.27 221.55C205.41 221.45 204.58 221.8 203.15 222.84L195.22 228.64L202.76 238.95L200.08 240.91L182.73 217.18L194.73 208.41C196.39 207.2 197.67 206.67 198.94 206.84C200.52 207.09 201.99 207.89 204.52 211.34C207.52 215.44 207.23 217.47 205.23 219.72L205.26 219.75C208 218.58 209.34 218.64 212.29 222.31C214.42 225.02 216.06 227.04 217.27 228.34L214.62 230.27C213.22 228.85 211.47 226.74 209.62 224.35C207.82 222.03 207.1 221.61 206.27 221.54V221.55ZM202.2 220.11C203.42 219.22 203.76 218.61 203.85 218.02C203.96 217.32 203.91 216.1 201.95 213.43C199.97 210.72 198.86 210.28 198.16 210.17C197.57 210.08 196.9 210.37 195.78 211.18L187.1 217.53L193.59 226.41L202.2 220.11Z" fill="white"/>
		<path d="M46.2 199.08C49.2155 199.08 51.66 196.635 51.66 193.62C51.66 190.605 49.2155 188.16 46.2 188.16C43.1845 188.16 40.74 190.605 40.74 193.62C40.74 196.635 43.1845 199.08 46.2 199.08Z" fill="white"/>
		<path d="M233.4 199.08C236.415 199.08 238.86 196.635 238.86 193.62C238.86 190.605 236.415 188.16 233.4 188.16C230.384 188.16 227.94 190.605 227.94 193.62C227.94 196.635 230.384 199.08 233.4 199.08Z" fill="white"/>
		<path d="M30.31 137.71L38.16 117.41C38.16 117.41 36.44 117.41 35.2 118.2C33.96 118.98 21.17 137.56 21.17 137.56C21.17 137.56 13.52 137.43 12.05 137.5C10.58 137.57 8.49996 138.55 7.70996 139.7C7.70996 139.7 7.70996 139.7 7.70996 139.72C7.70996 139.72 7.70996 139.72 7.70996 139.73C8.47996 140.89 10.55 141.9 12.02 141.98C13.49 142.06 21.14 142.01 21.14 142.01C21.14 142.01 33.74 160.72 34.97 161.51C36.2 162.31 37.92 162.33 37.92 162.33L30.28 141.95L48.8 142.16L52.83 148.03L55.83 147.87L53.72 140.96L56.64 139.97L53.83 138.93L55.92 132.06L52.92 131.87L48.83 137.7L30.3 137.72L30.31 137.71Z" fill="white"/>
		<path d="M245.31 141.82L237.46 162.12C237.46 162.12 239.18 162.12 240.42 161.33C241.66 160.55 254.45 141.97 254.45 141.97C254.45 141.97 262.1 142.1 263.57 142.03C265.04 141.96 267.12 140.98 267.91 139.83C267.91 139.83 267.91 139.83 267.91 139.81C267.91 139.81 267.91 139.81 267.91 139.8C267.14 138.64 265.07 137.63 263.6 137.55C262.13 137.47 254.48 137.52 254.48 137.52C254.48 137.52 241.88 118.81 240.65 118.02C239.42 117.22 237.7 117.2 237.7 117.2L245.34 137.58L226.82 137.37L222.79 131.5L219.79 131.66L221.9 138.57L218.98 139.56L221.79 140.6L219.7 147.47L222.7 147.66L226.79 141.83L245.32 141.81L245.31 141.82Z" fill="white"/>
		<path d="M46.2 95.33C49.2155 95.33 51.66 92.8855 51.66 89.87C51.66 86.8546 49.2155 84.41 46.2 84.41C43.1845 84.41 40.74 86.8546 40.74 89.87C40.74 92.8855 43.1845 95.33 46.2 95.33Z" fill="white"/>
		<path d="M233.4 95.33C236.415 95.33 238.86 92.8855 238.86 89.87C238.86 86.8546 236.415 84.41 233.4 84.41C230.384 84.41 227.94 86.8546 227.94 89.87C227.94 92.8855 230.384 95.33 233.4 95.33Z" fill="white"/>
		</svg>
			  
		</div>
	</div>
  );
};

export default SiteTitle;