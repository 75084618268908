import React from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

const CertificateNavigation = ({ certificates }) => {
  const uniqueMonths = Array.from(new Set(certificates.map(cert => moment(cert.most_recent_timestamp).format('YYYY-MM'))));
  const { year, month } = useParams();
  const currentYear = year || moment().format('YYYY');
  const currentMonth = month || moment().format('MM');

  return (
	<div className="certificate-navigation">
	  <ul>
		{uniqueMonths.map(month => {
		  const [navYear, navMonth] = month.split('-');
		  const isActive = navYear === currentYear && navMonth === currentMonth;

		  return (
			<li key={month}>
			  <Link 
				to={`/certificates/${navYear}/${navMonth}`} 
				className={isActive ? 'active' : ''}
			  >
				{moment(month, 'YYYY-MM').format('MMMM YYYY')}
			  </Link>
			</li>
		  );
		})}
	  </ul>
	</div>
  );
};

export default CertificateNavigation;
