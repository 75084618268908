  // Inline dataset
import React, { useState, useEffect } from 'react';

const AqAirports = ({ projection }) => {
  const airportsData = [
  { Name: "South Pole Station Airport", latitude_deg: -90, longitude_deg: 0 },
  { Name: "Aeródromo Williams", latitude_deg: -77.867401, longitude_deg: 167.057007 },
  { Name: "Teniente Rodolfo Marsh Martin Airport", latitude_deg: -62.191026, longitude_deg: -58.98669 },
  { Name: "Gustavo Marambio Airport", latitude_deg: -64.238297, longitude_deg: -56.630798 },
  { Name: "Palmer Station Airport", latitude_deg: -64.774582, longitude_deg: -64.035907 },
  { Name: "Petrel Airport", latitude_deg: -63.479, longitude_deg: -56.2313 },
  { Name: "Jubany Airbase", latitude_deg: -62.2383, longitude_deg: -58.666599 },
  { Name: "Casey Station Skiway", latitude_deg: -66.288056, longitude_deg: 110.7575 },
  { Name: "Esperanza Base", latitude_deg: -63.400002, longitude_deg: -56.983299 },
  { Name: "Halley Research Station", latitude_deg: -75.567406, longitude_deg: -25.516435 },
  //{ Name: "Orcadas Base Heliport", latitude_deg: -60.738473, longitude_deg: -44.735655 },
  { Name: "Matienzo Airfield", latitude_deg: -64.976998, longitude_deg: -60.108992 },
  { Name: "Patriot Hills Airport", latitude_deg: -80.3142, longitude_deg: -81.39478 },
  { Name: "Novolazarevskaya Station", latitude_deg: -70.8461, longitude_deg: 11.8472 },
  { Name: "Rothera Research Station", latitude_deg: -67.5675, longitude_deg: -68.127403 },
  { Name: "San Martín Base Airfield", latitude_deg: -68.116699, longitude_deg: -67.099998 },
  { Name: "Concordia Skyway", latitude_deg: -75.103278, longitude_deg: 123.35825 },
  { Name: "Vostok Skiway", latitude_deg: -78.466139, longitude_deg: 106.84825 },
  { Name: "Boulder Clay Runway", latitude_deg: -74.739373, longitude_deg: 164.03539 },
  { Name: "Wolfs Fang", latitude_deg: -71.527201, longitude_deg: 8.8333 },
  { Name: "Base Belgrano II Airport", latitude_deg: -77.874055, longitude_deg: -34.62616 },
  { Name: "General Bernardo O'Higgins Base Skyway", latitude_deg: -63.344, longitude_deg: -57.842 },
  { Name: "Aeródromo Phoenix", latitude_deg: -77.956389, longitude_deg: 166.766667 },
  { Name: "Wilkins Runway", latitude_deg: -66.690833, longitude_deg: 111.523611 },
  { Name: "Aeródromo de Punto Rothera", latitude_deg: -67.566941, longitude_deg: -68.126993 },
  { Name: "Showa Station Skiway", latitude_deg: -69.006167, longitude_deg: 39.59 },
  { Name: "Sky Blu Airstrip", latitude_deg: -74.849722, longitude_deg: -71.566666 },
  { Name: "Troll Airfield", latitude_deg: -71.95714, longitude_deg: 2.45257 },
  { Name: "Union Glacier Blue-Ice Runway", latitude_deg: -79.777778, longitude_deg: -83.320833 },
  { Name: "Enigma Lake Skiway", latitude_deg: -74.71637, longitude_deg: 164.03408 },
  { Name: "Zucchelli Ice Runway", latitude_deg: -74.68109, longitude_deg: 164.12756 },
  //{ Name: "Arctowsky Station Heliport", latitude_deg: -62.15941, longitude_deg: -58.47293 }
  { Name: "Comandante Ferraz Antarctic Station Helipad", latitude_deg: -62.0857, longitude_deg: -58.3915 }
  //{ Name: "Machu Picchu Base Helipad", latitude_deg: -62.09122, longitude_deg: -58.47103 }
  //{ Name: "Palmer Station Heliport", latitude_deg: -64.774501, longitude_deg: -64.051079 }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [processedData, setProcessedData] = useState([]);
  const [selectedAirport, setSelectedAirport] = useState(null);

  useEffect(() => {
	// Process the current airport using the projection
	if (currentIndex < airportsData.length) {
	  const airport = airportsData[currentIndex];
	  const [x, y] = projection([+airport.longitude_deg, +airport.latitude_deg]);
	  setProcessedData(processedData => [...processedData, { ...airport, projectedX: x, projectedY: y }]);
	}
  }, [currentIndex, projection]);

  useEffect(() => {
	// Update the current index every second (1000 milliseconds)
	const interval = setInterval(() => {
	  setCurrentIndex(currentIndex => {
		if (currentIndex < airportsData.length - 1) {
		  return currentIndex + 1;
		} else {
		  clearInterval(interval);
		  return currentIndex;
		}
	  });
	}, 100); // Adjust this duration to control the speed of loading

	return () => clearInterval(interval);
  }, []);

  const handleCircleClick = (airport) => {
	  setSelectedAirport(airport);
	};
  
	return (
	  <>
		{processedData.map((d, index) => (
		  <React.Fragment key={index}>
			<circle
			  className="airport-marker"
			  cx={d.projectedX}
			  cy={d.projectedY}
			  r="3"
			  fill="var(--color-brand-primary)"
			  onClick={() => handleCircleClick(d)}
			/>
		  </React.Fragment>
		))}
  
		{selectedAirport && (
		  <text
			className="airport-name"
			x={selectedAirport.projectedX + 10}
			y={selectedAirport.projectedY + 5} // Adjust this value as needed
			style={{ pointerEvents: 'none' }}>
			{selectedAirport.Name}
		  </text>
		)}
	  </>
	);
  };
  
  export default AqAirports;

