import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';  // Optional: Import for blur effect

const BigSingleImage = ({ imageItems }) => {
  return (
	<div className="single-image">
	  {imageItems.map((item, index) => (
		<div className="image-holder" key={index}>
		  <LazyLoadImage
			src={item.imageUrl}
			alt={`Image ${index}`}
			effect="blur" // Optional: You can remove this or use other effects
			// Other props like width, height can be added here
		  />
		  <figcaption>{item.caption}</figcaption>
		</div>
	  ))}
	</div>
  );
};

export default BigSingleImage;