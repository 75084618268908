import React, { useEffect, useRef, useState, useMemo } from 'react';
import * as d3 from 'd3';
import * as turf from '@turf/turf';
import aircraftTypes from '../components/AircraftTypes';
import { bearingToAzimuth } from '@turf/turf';

const AircraftMarkers = ({
  aircraftData,
  projection,
  setSelectedAircraft,
  selectedAircraftId,
  clearTrackFlag,
  onNearNorthPole
}) => {
  const markersRef = useRef();
  const [aircraftTrack, setAircraftTrack] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const NORTH_POLE = [0, 90]; // Longitude and Latitude of the North Pole

  const isNearNorthPole = (aircraft) => {
	const aircraftPosition = [aircraft.most_recent_position.lon, aircraft.most_recent_position.lat];
	const distanceToNorthPole = turf.distance(aircraftPosition, NORTH_POLE, { units: 'kilometers' });
	return distanceToNorthPole <= 100; // within 50 km
  };

  const createShockwave = (x, y) => {
	const svg = d3.select(markersRef.current);

	// Number of waves
	const numWaves = 3;

	for (let i = 0; i < numWaves; i++) {
	  svg.append('circle')
		.attr('cx', x)
		.attr('cy', y - 5)
		.attr('r', 1)
		.attr('fill', 'none')
		.attr('stroke', 'var(--color-red)')
		.attr('stroke-opacity', 0.8)
		.transition()
		.duration(1000)
		.ease(d3.easeSinOut)
		.attr('r', 25)
		.attr('stroke-opacity', 0)
		.remove();
	}
  };

  useEffect(() => {
	// Set the opacity to 0 initially and then transition to 1
	setOpacity(0);
	const timeoutId = setTimeout(() => setOpacity(1), 500); // adjust delay as needed
	return () => clearTimeout(timeoutId); // Cleanup timeout
  }, [aircraftData]);

  useEffect(() => {
	const svg = d3.select(markersRef.current);
	const defaultPath = "M 0,0 m -5,0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0";
	const trianglePath = "M0,-5 L-5,5 L5,5 Z";

	const markerWidth = 20; // Width of the marker
	const markerHeight = 20; // Height of the marker

	const markerGroups = svg.selectAll(".aircraft-marker-group")
	  .data(aircraftData, d => d.fr24_id)
	  .join(enter => enter.append("g").attr("class", "aircraft-marker-group"))
	  .attr("transform", d => getTransform(d)); 

	markerGroups.append("path")
	  .attr("class", "aircraft-marker")
	  .attr("d", d => aircraftTypes[d.type] || defaultPath)
	  .classed("near-north-pole", d => isNearNorthPole(d))
	  .classed("active", d => d.fr24_id === selectedAircraftId)
	  .style('opacity', '1')
	  .on("click", markerClick);

	function fetchAircraftTrack(fr24_id) {
		
	//https://data.ostia.goodcaesar.org/polar/recent-tracks.php	
		
	  fetch(`https://polarradar.com/recent-tracks.php?fr24_id=${fr24_id}`)
		.then(response => response.json())
		.then(data => {
		  setAircraftTrack(data);
		  drawTrack(data);
		})
		.catch(error => console.error('Error fetching aircraft track:', error));
	}

	function drawTrack(trackData) {
	  if (!trackData || !trackData.features || trackData.features.length === 0) {
		return;
	  }

	  d3.select('.aircraft-track').remove();

	  // Draw the new track
	  const line = d3.line()
		.x(d => projection(d)[0])
		.y(d => projection(d)[1]);

	  svg.append('path')
		.datum(trackData.features[0].geometry.coordinates)
		.attr('d', line)
		.attr('class', 'aircraft-track')
		.attr('fill', 'none')
		.attr('stroke', 'var(--color-brand-primary)')
		.attr('stroke-width', 2.5);
	}

	function getTransform(d) {
	  const previousCoords = projection([d.most_recent_position.lon, d.most_recent_position.lat]);
	  const recentCoords = projection([d.previous_position.lon, d.previous_position.lat]);

	  const  position = projection([d.most_recent_position.lon, d.most_recent_position.lat]);

	  const centerX = markerWidth / 2;
	  const centerY = markerHeight / 2;

	  const offsetX = markerWidth / 2;
	  const offsetY = markerHeight / 2;

	  const rotation = calculateDirection(previousCoords, recentCoords);

	  return `translate(${position[0]}, ${position[1]}) rotate(${rotation} ${centerX} ${centerY}) scale(1.15)`;
	}

	function calculateDirection(previousCoords, recentCoords) {
	  const from = turf.point(previousCoords);
	  const to = turf.point(recentCoords);
	  let bearing = turf.bearing(from, to);
	  bearing = (bearing + 360) % 360;
	  return bearing;
	}

	function markerClick(event, d) {
	  const [x, y] = projection([d.most_recent_position.lon, d.most_recent_position.lat]);
	  createShockwave(x, y);
	  setSelectedAircraft(d);
	  fetchAircraftTrack(d.fr24_id);
	  event.stopPropagation();
	}

	if (clearTrackFlag) {
	  clearTrack();
	  setSelectedAircraft(null);
	}

	return clearTrack;
  }, [aircraftData, projection, setSelectedAircraft, selectedAircraftId, clearTrackFlag]);

  // Memoize nearNorthPoleAircraft and onNearNorthPole
  const nearNorthPoleAircraft = useMemo(() => aircraftData.filter(isNearNorthPole), [aircraftData]);

  useEffect(() => {
	if (onNearNorthPole) {
	  onNearNorthPole(nearNorthPoleAircraft);
	}
  }, [nearNorthPoleAircraft, onNearNorthPole]);

  const clearTrack = () => {
	d3.select('.aircraft-track').remove();
  };

  return <g ref={markersRef} className="aircraft-markers"></g>;
};

export default AircraftMarkers;