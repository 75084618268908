// ModeHandler.js
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MapComponent from '../NewMap';

const ModeHandler = () => {
  const [mode, setMode] = useState('north');
  const location = useLocation();

  useEffect(() => {
	switch (location.pathname) {
	  case '/south':
		setMode('south');
		break;
	  case '/vintage':
		setMode('vintage');
		break;
	  default:
		setMode('north');
	}
  }, [location]);

  return <MapComponent mode={mode} />;
};

export default ModeHandler;
