import React, { useEffect, useRef, useState } from 'react';
import { geoPath, geoInterpolate, geoCentroid } from 'd3-geo';

const VintageData = ({ projection, coordinatePairs }) => {
  const [labelWidths, setLabelWidths] = useState({});
  const textRefs = useRef([]);

  useEffect(() => {
	const widths = {};
	textRefs.current.forEach((text, index) => {
	  if (text) {
		widths[index] = text.getBBox().width;
	  }
	});
	setLabelWidths(widths);
  }, [coordinatePairs]); // Rerun when coordinatePairs changes

  // Helper function to interpolate points between two locations
  const interpolatePoints = (start, end) => {
	const interpolate = geoInterpolate(start, end);
	return Array.from({ length: 100 }, (_, i) => interpolate(i / 99));
  };

  // Function to convert coordinates to SVG path data
  const convertCoordinatesToSVGPath = (coords) => {
	return coords.map((coord, index) => {
	  const [lon, lat] = coord;
	  return `${index === 0 ? 'M' : 'L'} ${projection([lon, lat])[0]} ${projection([lon, lat])[1]}`;
	}).join(' ');
  };

  return (
	<g className="route">
	  {coordinatePairs.map((pair, pairIndex) => {
		const pathData = convertCoordinatesToSVGPath(interpolatePoints(pair[0].coordinates, pair[1].coordinates));

		return (
		  <g key={pairIndex}>
			{/* Draw the paths */}
			<path
			  className="route"
			  d={pathData}
			  fill="none"
			  stroke="var(--color-brand-primary)"
			  strokeWidth="1.5"
			/>

			{/* City labels with dynamic background */}
			{pair.map((point, pointIndex) => {
			  const idx = pairIndex * 2 + pointIndex; // Unique index for each point
			  return (
				<g key={idx}>
				  {/* Conditional rendering of background rectangle */}
				  {labelWidths[idx] && (
					<rect
					  x={projection(point.coordinates)[0] - labelWidths[idx] / 14} // Center the rectangle and add padding
					  y={projection(point.coordinates)[1] - 10} // Adjust vertically as needed
					  width={labelWidths[idx] + 8} // Width of the text plus some padding
					  height={20} // Adjust height as needed
					  fill="var(--color-brand-primary)"
					/>
				  )}
				  {/* City label */}
				  <text
					ref={el => textRefs.current[idx] = el}
					x={projection(point.coordinates)[0]}
					y={projection(point.coordinates)[1]}
					dy=".35em"
					fill="var(--color-white)"
					fontSize="var(--font-size-xx-small)"
					className="point-label"
				  >
					{point.label}
				  </text>
				</g>
			  );
			})}
		  </g>
		);
	  })}
	</g>
  );
};

export default VintageData;
