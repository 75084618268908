import React, { useState, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import CertificateMap from '../components/CertificateMap';

const CertificateForm = ({ onSubmit, flight }) => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [base64Image, setBase64Image] = useState('');  // State to hold the base64 image

  const handleImageReady = useCallback((imageData) => {
	setBase64Image(imageData);  // Set the image data when ready
  }, []);

  const handleSubmit = async (e) => {
	e.preventDefault();
	setLoading(true);
	try {
	  // Include base64Image in the POST data
	  const response = await axios.post('/certificate.php', {
		image: base64Image,
		name,
		name,
		flight: flight?.flight,
		distance: flight.closest_distance,
		airline_full: flight?.airline_full,
		aircraft_full: flight?.type_full,
		time: moment(flight?.most_recent_timestamp).format('D MMMM'),
		year: moment(flight?.most_recent_timestamp).format('YYYY'),
		orig_city: flight?.orig_city,
		dest_city: flight?.dest_city,
		orig_iata: flight?.orig_iata,
		dest_iata: flight?.dest_iata,
	  }, { responseType: 'blob' });

	  // Handling the response to create a download link
	  const url = window.URL.createObjectURL(new Blob([response.data]));
	  const link = document.createElement('a');
	  link.href = url;
	  link.setAttribute('download', 'polar-certificate.pdf');
	  document.body.appendChild(link);
	  link.click();

	  // Call parent component's onSubmit function
	  onSubmit(name);
	  setName('');  // Optionally reset form fields
	} catch (error) {
	  console.error('Error generating PDF:', error);
	} finally {
	  setLoading(false);
	}
  };

  return (
	<div>
	  <form onSubmit={handleSubmit}>
		<label>
		  <input
			placeholder="Your name"
			type="text"
			value={name}
			onChange={(e) => setName(e.target.value)}
			required
			disabled={loading}
		  />
		</label>
		<button type="submit" disabled={loading || !base64Image}>
		  {loading ? 'Generating...' : 'Generate Certificate'}
		</button>
	  </form>
	  {flight && (
		<CertificateMap
		  routeUrl={`https://routes.ostia.goodcaesar.org/north/tracksv2.php?fr24_id=${flight.fr24_id}`}
		  type={flight.type}
		  airline={flight.airline} 
		  onImageReady={handleImageReady}
		/>
	  )}
	</div>
  );
};

export default CertificateForm;
