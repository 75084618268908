import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3-geo';

const PolarGraticuleLabels = ({ mode, projection }) => {
  const labelLatNorth = 80; // Latitude for labels around North Pole
  const labelLatSouth = -80; // Latitude for labels around South Pole
  const keyLongitudes = [-180, -150, -120, -90, -60, -30, 0, 30, 60, 90, 120, 150];

  const labelPositionsNorth = keyLongitudes.map(lon => {
	return { coords: projection([lon, labelLatNorth]), lon };
  });

  const labelPositionsSouth = keyLongitudes.map(lon => {
	return { coords: projection([lon, labelLatSouth]), lon };
  });

  const [labelSizes, setLabelSizes] = useState([]);
  const textRefs = useRef([]);
  textRefs.current = labelPositionsNorth.map((_, i) => textRefs.current[i] ?? React.createRef());

  useEffect(() => {
	const sizes = textRefs.current.map(ref => {
	  if (ref.current) {
		const { width, height } = ref.current.getBBox();
		return { width, height };
	  }
	  return { width: 0, height: 0 };
	});
	setLabelSizes(sizes);
  }, []);

  return (
	<>
	  {labelPositionsNorth.map((position, index) => (
		<React.Fragment key={index}>
		  {labelSizes[index] && (
			<rect
			  x={position.coords[0] - labelSizes[index].width / 2 - 2}
			  y={position.coords[1] - labelSizes[index].height / 2 - 2}
			  width={labelSizes[index].width + 4}
			  height={labelSizes[index].height - 2}
			  fill="var(--color-text-label-background)"
			  rx="2"
			  className="label-animation" // Add this class to apply the animation
			  style={{ animationDelay: `${index * 0.1}s` }} // Each label will appear with a delay
			/>
		  )}
		  <text
			ref={textRefs.current[index]}
			x={position.coords[0]}
			y={position.coords[1]}
			fill="var(--color-text-label)"
			fontSize="var(--font-size-xx-small)"
			textAnchor="middle"
			style={{ pointerEvents: 'none', userSelect: 'none', opacity: 0 }}
			className="label-animation" // Add this class to apply the animation
			style={{ animationDelay: `${index * 0.25}s` }} // Each label will appear with a delay
		  >
			{`${position.lon}°`}
		  </text>
		</React.Fragment>
	  ))}

	  {labelPositionsSouth.map((position, index) => (
		<React.Fragment key={index}>
		  {labelSizes[index] && (
			<rect
			  x={position.coords[0] - labelSizes[index].width / 2 - 2}
			  y={position.coords[1] - labelSizes[index].height / 2 - 2}
			  width={labelSizes[index].width + 4}
			  height={labelSizes[index].height - 2}
			  fill="var(--color-text-label-background)"
			  rx="2"
			  className="label-animation" // Add this class to apply the animation
			  style={{ animationDelay: `${index * 0.1}s` }} // Each label will appear with a delay
			/>
		  )}
		  <text
			ref={textRefs.current[index]}
			x={position.coords[0]}
			y={position.coords[1]}
			fill="var(--color-text-label)"
			fontSize="var(--font-size-xx-small)"
			textAnchor="middle"
			style={{ pointerEvents: 'none', userSelect: 'none', opacity: 0 }}
			className="label-animation" // Add this class to apply the animation
			style={{ animationDelay: `${index * 0.25}s` }} // Each label will appear with a delay
		  >
			{`${position.lon}°`}
		  </text>
		</React.Fragment>
	  ))}
	</>
  );
};

export default PolarGraticuleLabels;
