import React from 'react';
import { LazyLoadImage,trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';  // Optional: Import for blur effect

const ImageSwiper = ({ imageItems, scrollPosition }) => {
  return (
	<div className="image-show">
	  {imageItems.map((item, index) => (
		<div className="image-column" key={index}>
		  <LazyLoadImage
			src={item.imageUrl}
			alt={item.caption}
			height={item.heigth}
			width={item.width} 	
			scrollPosition={scrollPosition}		
			effect="blur"
		  />
		  <figcaption>{item.caption}</figcaption>
		</div>
	  ))}
	</div>
  );
};

export default ImageSwiper;