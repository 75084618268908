import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { zoom as d3Zoom, zoomIdentity } from 'd3-zoom';
import { select as d3Select } from 'd3-selection';
import { geoAzimuthalEqualArea } from 'd3-geo';
import { geoAzimuthalEquidistant } from 'd3-geo';
import moment from 'moment';
import useAircraftData from './components/useAircraftData';
import worldData from './data/world-50m.json';
import AircraftDetails from './components/AircraftDetails';
import AircraftMarkers from './components/AircraftMarkers';
import Countries from './components/Countries';
import PoleComponent from './components/PoleComponent';
import LastAircraftSeenComponent from './components/LastAircraftSeenComponent';
import ArcticCircleLabels from './components/ArcticCircleLabels';
import VintageData from './components/VintageData'; 
import CircleLineLabel from './components/CircleLineLabel';
import AqAirports from './components/AqAirports';
import AircraftTracks from './components/AircraftTracks';

const MapComponent = ({ mode, setMode }) => {
  const svgRef = useRef(null);
  const [selectedAircraft, setSelectedAircraft] = useState(null);
  const [aircraftTrack, setAircraftTrack] = useState(null);
  const [zoomTransform, setZoomTransform] = useState(zoomIdentity);
  //const aircraftData = useAircraftData(mode);    
  //const lastAircraftSeen = useAircraftData(mode);
  
  const { aircraftData, lastAircraftSeen } = useAircraftData();
  
  const [clearTrackFlag, setClearTrackFlag] = useState(false); 
  const [vintageData, setVintageData] = useState(null);    
  const navigate = useNavigate();
  const location = useLocation();
  const [clickedCoordinates, setClickedCoordinates] = useState(null);
  const [showTracks, setShowTracks] = useState(false);			
  const [currentTime, setCurrentTime] = useState('');
  const [aircraftCount, setAircraftCount] = useState(0);
  const [aircraftNearNorthPole, setAircraftNearNorthPole] = useState([]);  
  const [nearNorthPoleAircraftInfo, setNearNorthPoleAircraftInfo] = useState([]);
					
  const handleMapClick = () => {	  	  
	if (selectedAircraft) {
	  setSelectedAircraft(null);
	  setAircraftTrack(null);
	  setClearTrackFlag(true); 
	}
  };
   
  const handleAircraftSelect = (aircraft) => {
	console.log("Aircraft selected in MapComponent:", aircraft);
	setSelectedAircraft(aircraft);
  };

  	const getScale = () => {
	  // Define scales for different modes
	  const isMobile = window.innerWidth <= 768;
	  if (mode === 'north') {
		return zoomTransform.k * (isMobile ? 1000 : 1900);
	  } else if (mode === 'south') {
		return zoomTransform.k * (isMobile ? 420 : 1100);
	  } else if (mode === 'vintage') {
		return zoomTransform.k * (isMobile ? 320 : 450);
	  }
	};
  
  	const projection = useMemo(() => {
		let center = [0, 90]; // Default center for South
		if (mode === 'north') {
		  center = [0, -90];
		} else if (mode === 'vintage') {
		  // Define center for vintage mode
		  center  = [30, -100];
		}
		return geoAzimuthalEquidistant()
		  .rotate(center)
		  .scale(getScale())
		  .translate([zoomTransform.x + window.innerWidth / 2, zoomTransform.y + window.innerHeight / 2]);
	  }, [mode, zoomTransform]);
	
	const handleVintageClick = () => {
	  document.body.classList.remove('north', 'south', 'vintage');
	  if (location.pathname === '/vintage') {	
		document.body.classList.add('north');	  
		navigate('/'); // If already in vintage mode, navigate to north
	  } else {
		document.body.classList.add('vintage');	
		navigate('/vintage'); // Otherwise, navigate to vintage
	  }
	};
	
	useEffect(() => {
		const svgElement = d3Select(svgRef.current);	
	
	  // Define a function to handle click events
	  const handleMapClick = (event) => {
		// Get the mouse coordinates relative to the SVG element
		const rect = svgElement.node().getBoundingClientRect();
		const x = event.clientX - rect.left;
		const y = event.clientY - rect.top;
	
		// Use the projection to convert SVG coordinates to latitude and longitude
		const [lon, lat] = projection.invert([x, y]);
	
		// Log the clicked coordinates in the console
		console.log(lon.toFixed(4),lat.toFixed(4));
	  };
	
	  // Attach the click event listener to the SVG element
	  svgElement.on("click", handleMapClick);
	
	  return () => {
		// Remove the event listener when the component unmounts
		svgElement.on("click", null);
	  };
	}, [projection]); 
	
	const handleNearNorthPole = (nearNorthPoleAircraft) => {
	  // Update the state variable with the information about the aircraft
	  setNearNorthPoleAircraftInfo(nearNorthPoleAircraft);
	};	
	
	const formatDateAndTime = (dateString) => {
		// Parse the date string and format it
		return moment(dateString).format('MMMM Do YYYY, HH:mm');
	};
		
	const labelData = [
	  { coordinates: [-40, 77], label: "Greenland", color: "var(--color-white)", rotation: 40 },
	  { coordinates: [-140, 84], label: "Polar Icecap", color: "var(--color-white)", rotation: -45 },
	  { coordinates: [-67.3384, 72.4680], label: "Hudson Bay", color: "var(--color-white)", rotation: -20 },
	  { coordinates: [102.7368, 74.6809], label: "Russia", color: "var(--color-white)", rotation: -10 },
	  { coordinates: [44.0019, 77.7586], label: "Barents Sea", color: "var(--color-white)", rotation: 47 },
	];

	const coordinatePairs=[
		 [{ coordinates: [12.568337, 55.676098], label: 'Copenhagen' },{ coordinates: [-149.9003, 61.2181], label: 'Anchorage' }],
		 [{ coordinates: [-149.9003, 61.2181], label: 'Anchorage'},{ coordinates: [139.6917, 35.6895], label: 'Tokyo'}],	
		 [{ coordinates: [-149.9003, 61.2181], label: 'Anchorage' }, { coordinates: [139.6917, 35.6895], label: 'Tokyo' }],
		 [{ coordinates: [-0.127758,51.507351], label: 'London' }, { coordinates: [-149.9003, 61.2181], label: 'Anchorage' }],
		 [{ coordinates: [8.541694,47.376888], label: 'Zurich' }, { coordinates: [-149.9003, 61.2181], label: 'Anchorage' }],				
		 [{ coordinates: [126.977966,37.566536], label: 'Seoul' }, { coordinates: [-149.9003, 61.2181], label: 'Anchorage' }],
		 [{ coordinates: [4.8924534,52.3730796], label: 'Amsterdam' }, { coordinates: [-149.9003, 61.2181], label: 'Anchorage' }],		
		 [{ coordinates: [2.320041,48.8588897], label: 'Paris' }, { coordinates: [-149.9003, 61.2181], label: 'Anchorage' }],		
		 [{ coordinates: [10.734545,59.912942], label: 'Oslo' }, { coordinates: [-149.9003, 61.2181], label: 'Anchorage, USA' }],	
		 [{ coordinates: [24.938379,60.169857], label: 'Helsinki' }, { coordinates: [-167.6910,63.7336], label: '' }],					 
		 [{ coordinates: [-167.6910,63.7336], label: '' }, { coordinates: [139.6917, 35.6895], label: '' }],
		 [{ coordinates: [12.496365,41.902782	], label: 'Rome' }, { coordinates: [-149.9003, 61.2181], label: '' }],	
		 				 	
	  ];
	  
  return (
	<div>
		
	{ (mode === 'north' || mode === 'vintage') && (
		<div className="button-holder">
			<div className="button-wrapper">
			<button className={`vintage-mode ${mode === 'vintage' ? 'active' : ''}`} onClick={handleVintageClick}>
			<span>{mode === 'vintage' ? 'Exit Vintage' : 'Vintage Mode'}</span></button>		
			
			<button 
				className={`button-traffic ${showTracks ? 'active' : ''}`}
				onClick={() => setShowTracks(!showTracks)}
			>
				<span>{showTracks ? "Exit Replay" : "Replay 24H"}</span>
			</button> 
			
			</div>
		</div>
	)}
		
	{showTracks && (
		<div className="inline-message">
			<b>{ formatDateAndTime(currentTime) }</b>
		</div>
	)}
			
	{mode === 'vintage' && (
		<div className="inline-message">
			<b>Cold War airline routes connecting Europe with Japan and South Korea</b>
		</div>
	)}								
	   	
	{mode === 'north' && !showTracks && <LastAircraftSeenComponent 
		lastAircraftSeen={lastAircraftSeen} 
		isAircraftDisplayed={aircraftData.length >= 1} 
	  />}
		    
		
	{mode === 'north' && selectedAircraft && (
		<AircraftDetails
		  selectedAircraft={selectedAircraft} 
		  onClose={() => setSelectedAircraft(null)} 
		  nearNorthPoleAircraftInfo={nearNorthPoleAircraftInfo} // Pass the isNearNorthPole flag
		/>
	  )}
	  	  
	{mode === 'south' && (
		<div className="inline-message south">
			<span>It is quiet</span>
		</div>
	)}		   	
		   		
	  <svg ref={svgRef} className="map" width="100%" height="100%" onClick={handleMapClick}>
		<g transform={zoomTransform.toString()}>
	  		  
		  <Countries worldData={worldData} projection={projection} mode={mode} />
		  
		  { (mode === 'north' || mode === 'south') && (	  
		  <ArcticCircleLabels projection={projection} />
		  )}
		  	
		  {mode === 'south' && (
		  <AqAirports projection={projection} />
	 	)}		  
				
		 <PoleComponent projection={projection} mode={mode} />		
		
		{
		   mode === 'north' && 
		   labelData.map((data, index) => (
			 <CircleLineLabel
			   key={index}
			   mode={mode}
			   projection={projection}
			   coordinates={data.coordinates}
			   label={data.label}
			   circleRadius={4}
			   color={data.color}
			   rotation={data.rotation}
			 />
		   ))
		 }
		 		 
		 <AircraftTracks 
			 projection={projection}
			 showTracks={showTracks}
			 dataUrl="https://routes.ostia.goodcaesar.org/north/route-tracks.php?north"
			 setCurrentTime={setCurrentTime}
			 setAircraftCount={setAircraftCount}
		 />
		 
		{mode === 'vintage' && (		
			<VintageData projection={projection} coordinatePairs={coordinatePairs} />
		)}			
								
		{mode === 'north' && !showTracks && (  <AircraftMarkers 
				aircraftData={aircraftData} 
				projection={projection} 
				setSelectedAircraft={handleAircraftSelect}		
				selectedAircraftId={selectedAircraft ? selectedAircraft.fr24_id : null} // or selectedAircraft.fr24_id				
		/> )}				 
				  
		</g>        		
		
	  </svg>
	</div>
  );
};

export default React.memo(MapComponent);