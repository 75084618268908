import { useState, useEffect } from 'react';

const useAircraftData = () => {
  const [aircraftData, setAircraftData] = useState([]);
  const [lastAircraftSeen, setLastAircraftSeen] = useState('');

  useEffect(() => {
	const fetchData = async () => {
	  try {
		// Determine the URL based on the environment
		let apiUrl;
		if (window.location.hostname === 'localhost') {
		  // Use localhost URL for development
		  apiUrl = 'https://data.ostia.goodcaesar.org/polar/north-traffic.php';
		} else {
		  // Use production URL for other environments
		  apiUrl = '/north-traffic.php';
		}

		const response = await fetch(apiUrl);
		const data = await response.json();
		if (data.status === 'success') {
		  setAircraftData(data.data || []);		  
		  setLastAircraftSeen(data.last_aircraft_seen || '');
		}
	  } catch (error) {
		console.error('Error fetching aircraft data:', error);
	  }
	};

	fetchData();
	const intervalId = setInterval(fetchData, 30000);
	return () => clearInterval(intervalId);
  }, []);

  return { aircraftData, lastAircraftSeen };
};

export default useAircraftData;
