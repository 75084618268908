import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PoleComponent = ({ projection, mode, poleLabel, strokeColor }) => {
  const poleRef = useRef();

  useEffect(() => {
	const svg = d3.select(poleRef.current);
	svg.selectAll("*").remove(); // Clear previous contents

	const poleCoords = projection(mode === 'north' ? [0, 90] : [0, -90]);
	const textColor = mode === 'north' ? 'var(--color-white)' : 'var(--color-black)';
	const defaultStrokeColor = strokeColor || 'white';

	// Text
	svg.append("text")
	  .attr("class", "pole-text")
	  .attr("x", poleCoords[0] + 10)
	  .attr("y", poleCoords[1] - 10)
	  .attr("fill", textColor)
	  .text(poleLabel || (mode === 'north' ? "North Pole" : "South Pole"));

	// Pole representation (cross lines)
	svg.append("line")
	  .attr("x1", poleCoords[0] - 10)
	  .attr("y1", poleCoords[1])
	  .attr("x2", poleCoords[0] + 10)
	  .attr("y2", poleCoords[1])
	  .attr("stroke", defaultStrokeColor)
	  .attr("stroke-width", 4);

	svg.append("line")
	  .attr("x1", poleCoords[0])
	  .attr("y1", poleCoords[1] - 10)
	  .attr("x2", poleCoords[0])
	  .attr("y2", poleCoords[1] + 10)
	  .attr("stroke", defaultStrokeColor)
	  .attr("stroke-width", 4);

	if (mode === 'north') { // Ensure circles are only drawn for the North Pole
	  const earthRadius = 6371; // in kilometers
	  const distances = [100, 250, 500];
	 distances.forEach(distance => {
		 const radiusDegrees = distance / earthRadius * (180 / Math.PI);
		 const circlePath = d3.geoCircle().center([0, 90]).radius(radiusDegrees)();
		 let validPath = [];
		 circlePath.coordinates[0].forEach(coord => {
			 const projectedCoord = projection(coord);
			 if (!isNaN(projectedCoord[0]) && !isNaN(projectedCoord[1])) {
				 validPath.push(projectedCoord.join(","));
			 }
		 });
	 
		 if (validPath.length > 0) {
			 // Draw the circle
			 svg.append("path")
				 .attr("d", `M${validPath.join("L")}Z`)
				 .attr("fill", "none")
				 .attr("stroke-opacity","0.2")
				 .attr("stroke", defaultStrokeColor)
				 .attr("stroke-width", 0.5);
	 
			 // Calculate label position: you might need to adjust the logic depending on your projection
			 const labelPos = projection([0, 90 - radiusDegrees]); // Adjust as needed for correct positioning
	 
			 // Add label
			 svg.append("text")
				 .attr("x", labelPos[0] - 15) // Slightly offset to the right of the circle
				 .attr("y", labelPos[1] + 15) // Aligned vertically with the circle
				 .attr("fill", "white") // Adjust color to match your design
				 .style("font-size", "10px") // Adjust size as needed
				 .text(`${distance} km`);
		 } else {
			 console.error(`Invalid path data for distance ${distance} km, all coordinates were NaN.`);
		 }
	 });
	}
  }, [projection, mode, poleLabel, strokeColor]);

  return <g ref={poleRef} className="pole"></g>;
};

export default PoleComponent;
