import React, { useState, useEffect } from 'react';
import moment from 'moment';
import AircraftImage from '../components/AircraftImage';
import * as turf from '@turf/turf';
import { CSSTransition } from 'react-transition-group';
import NorthPoleCertificate from '../components/NorthPoleCertificate'; // Import the new component

const AircraftDetails = ({ selectedAircraft, onClose }) => {
	const [isVisible, setIsVisible] = useState(false);
	const [animationClass, setAnimationClass] = useState('enter');
	const [isNearNorthPole, setIsNearNorthPole] = useState(false); // New state variable
	const [showCertificate, setShowCertificate] = useState(false);
	const [distanceToNorthPole, setDistanceToNorthPole] = useState(null); // Declare the variable
	const NORTH_POLE = [0, 90]; // Longitude and Latitude of the North Pole

	useEffect(() => {
		if (selectedAircraft) {
			setIsVisible(true); // Show the component
			setAnimationClass('enter'); // Start enter animation

			// Check if the aircraft is near the North Pole
			const aircraftPosition = [selectedAircraft.most_recent_position.lon, selectedAircraft.most_recent_position.lat];

			const distance = turf.distance(aircraftPosition, NORTH_POLE, { units: 'kilometers' });
			setDistanceToNorthPole(distance); // Set the distance here

			setIsNearNorthPole(distanceToNorthPole <= 100); // Set to true if within 50 km
		} else if (isVisible) {
			setAnimationClass('leave'); // Start leave animation
		}
	}, [selectedAircraft, isVisible, distanceToNorthPole]);

	const handleAnimationEnd = () => {
		if (animationClass === 'leave') {
			setIsVisible(false); // Hide the component after animation
			onClose(); // Call the onClose callback
		}
	};

	const toggleCertificate = () => {
		setShowCertificate(!showCertificate);
		setIsNearNorthPole(!isNearNorthPole); // Toggle the active state
	};

	if (!isVisible && !selectedAircraft) {
		return null;
	}

	const calculateTimeRemaining = (targetDate) => {
		const now = moment();
		const target = moment(targetDate);
		const duration = moment.duration(target.diff(now));

		if (duration.asSeconds() > 0) {
			const hours = Math.floor(duration.asHours());
			const mins = duration.minutes();
			const secs = duration.seconds();
			return `${hours} h ${mins} m`;
		} else {
			return 'Expired';
		}
	};

	const etaTimeRemaining = calculateTimeRemaining(selectedAircraft.eta);

	return (
		<div
			className={`aircraft-details ${isNearNorthPole ? 'north-pole' : ''} aircraft-details-${animationClass}`}
			onAnimationEnd={handleAnimationEnd}
		>
			{selectedAircraft && (
				<div className="details-wrapper">
					<div className="context-frame">
						<div className="airline-header">
							<button className="close" onClick={() => onClose()}>&times;</button>
							<div className="airline">{selectedAircraft.airline_full}</div>
							<div className="aircraft"><span>{selectedAircraft.type_full}</span></div>
						</div>
						<div className="contextual-info">
							<div className="orig">
								<h4>{selectedAircraft.orig_iata || 'Unknown'}</h4>
								<small>{selectedAircraft.orig_city || 'Unknown' }</small>
							</div>
							<div className="connected">
								<b>Landing in</b>
								<b>{etaTimeRemaining}</b>
							</div>
							<div className="dest">
								<h4>{selectedAircraft.dest_iata || 'Unknown'}</h4>
								<small>{selectedAircraft.dest_city || 'Unknown' }</small>
							</div>
						</div>
						<NorthPoleCertificate selectedAircraft={selectedAircraft} distanceToNorthPole={distanceToNorthPole} showCertificate={showCertificate} />
						{isNearNorthPole && (
							<button className={`certificate-btn ${showCertificate ? 'active' : ''}`} onClick={toggleCertificate}>
								<small>{showCertificate ? 'Close Certificate' : 'Certificate available'}</small>
							</button>
						)}
						<div className="tech">
							<small>
								<span>Lat: {selectedAircraft.most_recent_position.lat.toFixed(2)}</span>
								<span>Lon: {selectedAircraft.most_recent_position.lon.toFixed(2)}</span>
								<span>Track: {selectedAircraft.track}</span>
								<span>Flight: {selectedAircraft.flight}</span>
								<span>Reg: {selectedAircraft.reg}</span>
							</small>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AircraftDetails;
