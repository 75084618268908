import React from 'react';
import moment from 'moment';

const LastAircraftSeenComponent = ({ lastAircraftSeen, isAircraftDisplayed }) => {
  // Parse the timestamp as UTC, then convert to local time
  const date = moment.utc(lastAircraftSeen).local();
  
  // Check if the parsed date is valid
  const isValidDate = date.isValid();

  // Calculate custom "since" format
  const getCustomSinceFormat = () => {
	const now = moment();
	const duration = moment.duration(now.diff(date));
	const hours = duration.hours();
	const minutes = duration.minutes();
	let result = '';
	if (hours > 0) {
	  result += `${hours}h`;
	}
	if (minutes > 0) {
	  result += (result ? '' : '') + `${minutes}m`;
	}
	return result + ' ago';
  };

  // Only render the component if no aircraft is displayed and the date is valid
  if (!isAircraftDisplayed && isValidDate) {
	return (
	  <div className="inline-message">
		<span>Last aircraft seen <b>{getCustomSinceFormat()}</b></span>
	  </div>
	);
  } else if (!isAircraftDisplayed) {
	// Fallback message if date is not valid
	return (
	  <div className="inline-message">
		<span>No recent aircraft data available.</span>
	  </div>
	);
  }

  return null; // Return null to render nothing when aircraft are displayed or if the date is valid
};

export default LastAircraftSeenComponent;