import React from 'react';

const CircleLineLabel = ({ mode, projection, coordinates, label, circleRadius, color, rotation }) => {
  const [x, y] = projection(coordinates);

  return (
	<g>
	  <text
		x={x}
		y={y}
		fill={color}
		fontSize="var(--font-size-small)"
		textAnchor="middle"
		transform={`rotate(${rotation} ${x} ${y})`}
	  >
		{label}
	  </text>
	</g>
  );
};

export default CircleLineLabel;
