import React, { useEffect, useRef, useCallback } from 'react';
import ImageSwiper from '../components/ImageSwiper';
import { articleContent } from '../components/ArticleData';
import BigSingleImage from '../components/BigSingleImage';
import GlobeRoute from '../components/globeRoute';
import 'react-lazy-load-image-component/src/effects/blur.css';

function AboutPage() {
	// Create a ref for each section
	const sectionRefs = useRef(articleContent.map(() => React.createRef()));

	const renderAnswer = (answer) => {
		return { __html: answer };
	};

	const renderFilm = (content) => {
		return `https://www.youtube.com/embed/${content}`;
	};

	const handleIntersection = useCallback((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('in-view');
			} else {
				entry.target.classList.remove('in-view');
			}
		});
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
	
		sectionRefs.current.forEach(ref => {
			if (ref.current) observer.observe(ref.current);
		});
	
		return () => {
			sectionRefs.current.forEach(ref => {
				if (ref.current) observer.unobserve(ref.current);
			});
		};
	}, [handleIntersection]);

	const headerImageUrl = '/img/pan-am-antarctica.png'; 
	const header_webm = '/img/looped.webm';
	const header_mp4 = '/img/looped-mute.mp4';

	return (
		<article className="page story">
			<header className="story-header">
				<div className="route-holder">
					<div className="svg-holder">
						<GlobeRoute /> 
					</div>
					
					<div className="headline">
						<h1>Polar Radar</h1>
						<h2>Tracking flights over the North &amp; South Pole</h2>
					</div>
					
				</div>
				
				<div className="hero-video">		
					<video preload="true" autoPlay loop playsInline muted poster={headerImageUrl}>
						  <source src={header_webm} type="video/webm" />
						  <source src={header_mp4} type="video/mp4" />
					  </video>	
				</div>
			</header>

			<div className="inside-page">
				{articleContent.map((section, index) => {
					return (
						<div 
							ref={sectionRefs.current[index]} 
							data-section-type={section.type}
							key={index} 
							className="article-segment"
						>
							<div className="segment-inner">
							
							{section.type === 'text' && (
								<>
									<div className="paragraphs">
										<div className="copy" dangerouslySetInnerHTML={renderAnswer(section.content)} />
									</div>
									{section.note && (
										<div className="note">
											<small dangerouslySetInnerHTML={renderAnswer(section.note)} />
										</div>
									)}
								</>
							)}
							
							{section.type === 'headline' && (
								<div className="paragraphs">
									<div className="copy">
										<h3>{section.content}</h3>
									</div>
								</div>
							)}
							
							{section.type === 'big-single-image' && (
								<div className="single-image">
									<BigSingleImage key={index} imageItems={section.content} />
								</div>
							)}
							
							{section.type === 'images' && (
								<ImageSwiper imageItems={section.content} />
							)}
							
							{section.type === 'film' && (
								<div className="film-wrapper">			
								  <iframe 
									width="560" 
									height="315" 
									src={renderFilm(section.content)} 
									title="YouTube video player" 
									frameborder="0" 
									allow="autoplay; clipboard-write; encrypted-media; " 
									allowfullscreen
								  ></iframe>  
								  </div>
							)}
							
							</div>
						</div>
					);
				})}
			</div>
		</article>
	);
}

export default AboutPage;