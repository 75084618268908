import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import AboutPage from './pages/AboutPage.js';
import DataPage from './pages/DataPage.js';
import TimeTablePage from './pages/TimeTablePage.js';
import Certificates from './pages/Certificates'; // Use the updated Certificates component
import './style.css';
import { Helmet } from 'react-helmet';
import NavigationBar from './components/Navigation';
import ModeHandler from './components/ModeHandler';

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  const location = useLocation();
  
  const initialMapState = (() => {
    switch (location.pathname) {
      case '/south':
        return 'south';
      case '/vintage':
        return 'vintage';
      default:
        return 'north';
    }
  })();

  const [mapState, setMapState] = useState(initialMapState);
  const isProduction = process.env.NODE_ENV === 'production';

  const toggleMapState = () => {
    setMapState(mapState === 'north' ? 'south' : 'north');
  };

  useEffect(() => {
    // Add the mapState as a class to the body
    document.body.classList.add(mapState);

    // Clean up function to remove the class when the component unmounts or mapState changes
    return () => {
      document.body.classList.remove(mapState);
    };
  }, [mapState]); // Run the effect when mapState changes

  return (
    <div>
      <Helmet>
        <title>Polar Radar: Tracking flights over the North and South Pole</title>
        <link rel="stylesheet" type="text/css" href="https://cloud.typography.com/638248/7703832/css/fonts.css" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="Polar Radar is a flight tracking site monitoring aircraft movement over the North Pole and South Pole." />
        
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Forum&family=Karla:wght@300;400;800&display=swap" rel="stylesheet" />
        
        {isProduction && (
          <>
            <script defer data-domain="polarradar.com" src="https://plausible.io/js/script.js"></script>
          </>
        )}
      </Helmet>

      <NavigationBar mapState={mapState} toggleMapState={toggleMapState} />

      <Routes>
        <Route exact path="/" element={<ModeHandler />} />
        <Route path="/south" element={<ModeHandler />} />
        <Route path="/vintage" element={<ModeHandler />} />
        <Route path="/timetable" element={<TimeTablePage />} />
        <Route path="/certificates" element={<Certificates />} />
        <Route path="/certificates/:year/:month" element={<Certificates />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/data" element={<DataPage />} />
      </Routes>
    </div>
  );
};

export default App;
