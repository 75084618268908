import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CertificateForm from '../components/CertificateForm';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import MonthlyNavigation from '../components/CertificateNavigation';

const Certificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [source, setSource] = useState('live'); // Default source is 'live'
  const { year, month } = useParams();

  useEffect(() => {
	const fetchCertificates = async () => {
	  try {
		let url;
		
		if (window.location.hostname === 'localhost') {
		  url = 'https://routes.ostia.goodcaesar.org/north/certificate-flights.json';
		} else {
		  url = '/certificates.json'; // Adjust the path for your localhost
		}

		const response = await axios.get(url);
		console.log('Response data:', response.data);
		if (Array.isArray(response.data)) {
		  setCertificates(response.data);
		} else {
		  console.error('Response data is not an array:', response.data);
		}
	  } catch (error) {
		console.error('Error fetching certificates:', error);
	  }
	};

	fetchCertificates();
  }, [source]); // Include source in the dependency array

  const handleFlightClick = (flight) => {
	setSelectedFlight(flight === selectedFlight ? null : flight);
  };

  const handleGeneratePDF = async (name, flight) => {
	// Implement PDF generation logic here
	console.log('Generating PDF for', name, 'for flight:', flight);
  };

  // Determine the current year and month if not provided in the URL
  const currentYear = year || moment().format('YYYY');
  const currentMonth = month || moment().format('MM');

  return (
	<div className="page data">
	  <div className="inside-page">
		<h1>Certificates</h1>
		
		<div className="page-header">
		  <p>Create a personalised certificate to commemorate <b>your</b> crossing of the North Pole. This page lists all flights that came within <b>100km of the North Pole since 1 January 2024</b>.</p>
		</div>

		<MonthlyNavigation certificates={certificates} />
		
		<CertificateList 
		  certificates={certificates} 
		  handleFlightClick={handleFlightClick} 
		  selectedFlight={selectedFlight} 
		  handleGeneratePDF={handleGeneratePDF} 
		  year={currentYear} 
		  month={currentMonth}
		/>
	  </div>
	</div>
  );
};

const CertificateList = ({ certificates, handleFlightClick, selectedFlight, handleGeneratePDF, year, month }) => {
  const filteredCertificates = certificates.filter(cert => moment(cert.most_recent_timestamp).format('YYYY-MM') === `${year}-${month}`);
  
  return (
	<div className="certificates">
	  {filteredCertificates.length === 0 ? (
		<p className="no-certificates">No certificates available for {moment(`${year}-${month}`).format('MMMM YYYY')} yet. Come back tomorrow or browse other months above.</p>
	  ) : (
		filteredCertificates.map((certificate, index) => (
		  <div className={`certificate ${selectedFlight === certificate ? 'active' : ''}`} key={index}>
			
			<div className="flight-info"><b>{moment(certificate?.most_recent_timestamp).format('D MMMM, YYYY')}</b>
			  <small>{certificate?.airline_full} ({certificate?.flight}): {certificate?.orig_city}&#8212;{certificate?.dest_city}</small></div>
			  
			  <button className="create" onClick={() => handleFlightClick(certificate)}>Certificate available</button>
			
			{selectedFlight === certificate && (
			  <CertificateForm
				key={certificate.fr24_id}
				onSubmit={(name) => handleGeneratePDF(name, certificate)}
				flight={selectedFlight}
			  />
			)}
		  </div>
		))
	  )}
	</div>
  );
};

export default Certificates;
